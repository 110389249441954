
import { useUpdateNodeData, } from '../../../hooks/updateNodeHooks';
import { MessageNode } from '../../../atoms/flow';
import styled from 'styled-components';
import { useUpdateNodeInternals } from 'reactflow';

const ResetField = (node: MessageNode) => {
	const updateNodeDataWithId = useUpdateNodeData();

	const updateNodeInternals = useUpdateNodeInternals();

	const setChecked = (checked: boolean) => {
		updateNodeDataWithId(node.id, {field: 'reset', newValue : checked});
		node && updateNodeInternals(node.id);
	};

	return (  
		<Container>
			<p>Use as reset, this wil add a fade back to an earlier moment:</p>
			<input 
				type={'checkbox'} 
				checked={node?.data.reset}
				onChange={(e) => setChecked(e.target.checked)}
			/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;
 
export default ResetField;