import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export const storage = createJSONStorage<any>(() => sessionStorage);
export const activeLanguageAtom = atomWithStorage('editor_language','en', storage);
export const enableMessageNumbersAtom = atomWithStorage('numbers_enabled',false, storage);

export type ObjectEntries<T> = { 
	[K in keyof T]: {field: K, newValue: T[K]} 
}[keyof T]


export const themeAtom = atomWithStorage<'light' | 'dark'>('theme', 'light');