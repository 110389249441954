import { useSetAtom } from 'jotai';
import { Scenario } from '../../atoms/menu';
import { selectedScenarioIdAtom } from '../../atoms/editor';
import styled from 'styled-components';
import { Tile } from './Tile';
import empty_env from '../../assets/images/empty_env.jpg';

const ScenarioTile = ({scenario}: {scenario: Scenario}) => {

	const setSelectedScenarioId = useSetAtom(selectedScenarioIdAtom);

	const openScenario = () => { 
		setSelectedScenarioId(scenario.id);
	};

	return (
		<Container $background={scenario.environment ? `https://tqwi.directus.app/assets/${scenario.environment}` : empty_env} onClick={openScenario}>
			<Text>
				<p>{scenario.name}</p>
				<p>{scenario.status === 'live' ? ' • Live' : ''}</p>
			</Text>
		</Container>
	);
};

const Container = styled(Tile)<{$background: string}>`

	background-image: url(${p => p.$background});
	background-size: cover;

	justify-content: flex-end;
    color: ${p => p.theme.colors.neutralLightest};
	text-shadow: 1px 1px 2px black;

`;

const Text = styled.div`
	margin: 0;
	& > p {margin: 0}
	& > :nth-child(2) {
		color: ${p => p.theme.colors.correct};
	}
	display: flex;
	gap: 5px;
`;

 
export default ScenarioTile;