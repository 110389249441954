import { GlobalStyle } from './GlobalStyle';
import { alternativeTheme, theme } from './theme';
import {ThemeProvider} from 'styled-components';
import { ReactNode } from 'react';
import { useAtomValue } from 'jotai';
import { themeAtom } from '../atoms/atoms';

const StyleProvider = ({children} : {children: ReactNode}) => {

	const mode = useAtomValue(themeAtom);

	return ( 
		<ThemeProvider theme={mode === 'light' ? theme : alternativeTheme}>
			<GlobalStyle/>
			{children}
		</ThemeProvider>
	);
};
 
export default StyleProvider;