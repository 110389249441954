import { atom } from 'jotai';
import { NodeData, Sceneswap } from './flow';
import { atomWithStorage } from 'jotai/utils';

export const menuDataAtom = atom<MenuData | undefined>(undefined);
export const searchedScenarioAtom = atom<string>('');
export const recentScenariosAtom = atomWithStorage<number[]>('recent_scenarios', []);

export type MenuData = {
	scenarios: {[id: number]: Scenario},
}

//#region Scenario Types

export type Scenario =  {
	id: number
	starting_message: number | null,
	translations: number[],
	node_data: NodeData,
	scene_swaps: Sceneswap[],
	status: 'archived' | 'draft' | 'live' | 'ready for review' 
} & ScenarioSettings

export type ScenarioSettings = {	
	name: string	
	environment: string | null,
	characters: number[]
	languages: string[],
}



//#endregion