import { MessageNodeData } from './MessageNode';
import Row from '../components/NodeRow';
import styled, { useTheme } from 'styled-components';
import { useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../atoms/atoms';
import { Position, useNodeId } from 'reactflow';
import { PiSmileyBold, PiSmileyMehBold, PiSmileySadBold } from 'react-icons/pi';
import FollowUpMessageHandle from '../../handles/FollowUpMessageHandle';

const Answers = ({answers}: AnswerProps) => {

	const nodeId = useNodeId();

	const language = useAtomValue(activeLanguageAtom);
	const theme = useTheme();

	return ( 
		<>
			{ Object.keys(answers).length != 0 && 
				<>
					<AnswersRow>
						<h3>Answers</h3>						
						{Object.entries(answers).map(([id,answer]) => 
							<Answer key={`message-node-${nodeId}-${id}`}>
								<p>{answer.translations[language] ? answer.translations[language].text : 'no translation found'}</p>
								{ 
									answer.correctness === 0 ? <PiSmileySadBold color={theme.colors.danger}/>: 
										answer.correctness === 1 ? <PiSmileyMehBold color={theme.colors.alert}/> :
											answer.correctness === 2 ?  <PiSmileyBold  color={theme.colors.correct}/> :
												''
								}
								<FollowUpMessageHandle 
									type="source" 
									id={`source-${nodeId}-${id}`} 
									position={Position.Right} 
									isConnectable={answer.follow_up_message === null} 
									style={{
										insetInlineEnd: `-${(theme.styles.padding) + (theme.styles.borderWidth/ 2)}px`,
									}}
								/>
							</Answer>
						)}
					</AnswersRow>
				</>
			}
		</>
	);
};

// sc

const Answer = styled.div`
	display: flex;
	gap: 5px;
	position: relative;
	justify-content: flex-start;
	align-items: center;

	background-color: ${p => p.theme.colors.neutralLighter};
	padding-inline: ${p => p.theme.styles.padding * 2}px;
	border-radius: 50em;
	padding-block: ${p => p.theme.styles.padding}px;

	& > p {
		margin :0;
	}

	& > svg {
		margin-inline-start: auto;
		font-size: 2em;
		border-radius: 100%;
	}
`;

const AnswersRow = styled(Row)`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.styles.padding / 2}px;
`;

type AnswerProps = {
	answers: MessageNodeData['answers']
} 
 
export default Answers;