import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TextField } from './components/TextField';
import { useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../atoms/atoms';
import { useUpdateNodeData, } from '../../../hooks/updateNodeHooks';
import { MessageNode } from '../../../atoms/flow';
import CharactersDropdown from './components/CharactersDropdown';
import { Label } from '../../misc/Label';

const MessageField = (node: MessageNode) => {
	const language = useAtomValue(activeLanguageAtom);
	const updateNodeDataWithId = useUpdateNodeData();

	//#region speaking character

	const speakingCharacter = useMemo(() => {
		if(!node ||  node.data.speaking_character === null) return 0;
		return node.data.speaking_character;
	},[node]);

	const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
		updateNodeDataWithId(node.id, {field: 'speaking_character', newValue: e.target.value === 'null' ? null : parseInt(e.target.value)});
	};

	//#endregion

	//#region Message Field

	const [message, setMessage] = useState('');

	useEffect(() => {
		if(!node || !language) return;
		setMessage(node.data.translations[language] ? node.data.translations[language].text : '');
	}, [node, language]);

	const [overwriteName, setOverwriteName] = useState('');

	useEffect(() => {
		if(!node || !language || !node.data.translations[language] || !node.data.translations[language].overwrite_name) return;
		setOverwriteName(node.data.translations[language].overwrite_name);
	}, [node, language]);

	const onMessageBlur = () => {
		if(!node) return;
		const updatedTranslations = {...node.data.translations};
		if(updatedTranslations[language])
			updatedTranslations[language] = {directus_id: updatedTranslations[language].directus_id, text: message, overwrite_name: overwriteName};
		else
			updatedTranslations[language] = {directus_id: null, text: message, overwrite_name: overwriteName};
		updateNodeDataWithId(node.id, {field: 'translations', newValue : updatedTranslations});	
	};

	//#endregion

	return (  
		<>
			{node?.data.message_type === 0 && <div>
				<Label>
        speaking:
					<CharactersDropdown playerOption value={speakingCharacter} onSelectChange={onSelectChange}/>
				</Label>
				<Label>
        overwrite name:
					<input
						onChange={(e) => setOverwriteName(e.target.value)}
						onBlur={onMessageBlur}
						value={overwriteName}
					/>
				</Label>
			</div>}
			<h3>
				Message 
			</h3>
			<TextField>
				<textarea
					onChange={(e) => setMessage(e.target.value)}
					onBlur={onMessageBlur}
					placeholder='message'
					value={message}
				/>
			</TextField>
		</>
	);
};
 
export default MessageField;