import styled from 'styled-components';

// sc
export const Header = styled.div`
	display: flex;
	align-items: center;
	gap: ${p => p.theme.styles.padding}px;
	& > h3 {
		margin-bottom: 0;
	}
`;
