
// Light Theme

export const theme = {
	colors: {
		neutralLightest: 'hsl(0, 0%, 100%)',
		neutralLighter: 'hsla(0, 0%, 96%, 1)',
		neutralLight: 'hsl(0, 0%, 87%)',
		neutral: 'hsl(0, 0%, 60%)',
		neutralDark: 'hsl(0, 0%, 33%)',
		neutralDarker: 'hsl(0, 0%, 20%)',
		neutralDarkest: 'hsl(0, 0%, 0%)',
		primary: 'hsla(223, 100%, 66%, 1)',
		primaryLighter: 'hsla(223, 100%, 66%, 0.2)',

		danger: '#eb4034',	
		alert: '#ed9945',	
		correct: '#55D25A',	

		tinqwise: '#d40000'
	},
	spacing: {
		gap: 20,
		hug: 40,
	},
	styles: { // style
		borderRadius: 10,
		padding: 10,
		borderWidth: 5,
		alpha: 0.7,
		boxShadow: '0px 4px 15px 0px hsla(0, 0%, 0%, 0.1)'
	},
	note_colors: [
		'#fdffab',
		'#91e7e1',
		'#ebcbfe',
		'#ffacad'
	]
};

// Dark Theme

export const alternativeTheme : typeof theme = {
	colors: {
		neutralLightest: 'hsla(220, 10%, 20%, 1)',
		neutralLighter: 'hsla(0, 0%, 96%, 1)',
		neutralLight: 'hsl(0, 0%, 66%)',
		neutral: 'hsl(0, 0%, 50%)',
		neutralDark: 'hsl(0, 0%, 33%)',
		neutralDarker: 'hsl(0, 0%, 20%)',
		neutralDarkest: 'hsl(0, 0%, 100%)',
		primary: 'hsl(100, 50%, 50%)',
		primaryLighter: 'hsla(223, 100%, 66%, 0.2)',

		danger: '#eb4034',	
		alert: '#ed9945',	
		correct: '#32a852',

		tinqwise: '#d40000'
	},
	spacing: {
		gap: 20,
		hug: 40,
	},
	styles: { // style
		borderRadius: 10,
		padding: 10,
		borderWidth: 5,
		alpha: 0.7,
		boxShadow: '0px 4px 15px 0px hsla(0, 0%, 0%, 0.1)'
	},
	note_colors: [
		'#ff7eb9',
		'#7afcff',
		'#feff9c'
	]
};

export type Theme = typeof theme;
