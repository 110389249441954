import { useAtom } from 'jotai';
import { errorsAtom } from './Focused';
import { FocusedWindow } from './FocusedWindow';

const Error = () => {
	const [error,] = useAtom(errorsAtom);
	
	return ( 
		<FocusedWindow $innerContainerProps={{style: {backgroundColor: 'rgb(235, 64, 52, 0.7)'}}} title={'Error'}>
			Oops, it looks like you have encountered an error, please refresh the page. If it keeps happening contact the games development team.
			<br></br>
			<h4>Details</h4> 
			<>
				{error}
			</>
		</FocusedWindow> 
	);
};
 
export default Error;