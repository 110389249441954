import { MenuData } from '../../atoms/menu';
import { retrieveItemsFromCollection } from '../../tools/directusActions/read';

const retrieveMenuData = async() => {

	// retrieve scenarios
	const scenarios : MenuData['scenarios'] = await retrieveItemsFromCollection('product_2_scenarios',{fields: ['id', 'name', 'environment', 'status']});

	const data: MenuData = {

		scenarios: scenarios,
	};

	return data;
};

export default retrieveMenuData;