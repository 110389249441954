import { useAtom, } from 'jotai';
import { FlowData, edgesAtom, nodesAtom } from '../../atoms/flow';
import { AnswerTranslations, MessageNodeData, MessageTranslations } from '../../Editor/nodes/MessageNode/MessageNode';
import { useAddEdge } from '../../hooks/edgeHooks';
import { EndingTranslations } from '../../Editor/nodes/EndingNode/EndingNode';
import { IntroductionTranslations } from '../../Editor/nodes/IntroductionNode/IntroductionNode';

export const useLoadNodes = () => {

	const [, setNodes] = useAtom(nodesAtom);
	const [, setEdges] = useAtom(edgesAtom);
	const onAddEdge = useAddEdge();

	return async (flowsData: FlowData) => {
		setEdges([]);
		setNodes([]);
		if (!flowsData) return;

		//setup message translations
		const st: IntroductionTranslations = {};
		flowsData.scenario.translations.map((tr) => {
			st[flowsData.scenarioTranslations[tr].product_2_languages_code] = {
				directus_id: tr,
				name: flowsData.scenarioTranslations[tr].name,
				description: flowsData.scenarioTranslations[tr].description,
				ellie_description: flowsData.scenarioTranslations[tr].ellie_description
			};
		});

		//add introduction node
		await setNodes([
			{
				id: 'introduction' + flowsData.scenario.id.toString(),
				position: flowsData.scenario.node_data ? { x: flowsData.scenario.node_data.positions.x, y: flowsData.scenario.node_data.positions.y } : { x: 0, y: 0 },
				type: 'introduction',
				data: {
					directus_id: flowsData.scenario.id,
					translations: st,
					starting_message: flowsData.scenario.starting_message
				}
			} 
		]);

							
		//add edge for introduction
		if(flowsData.scenario.starting_message)
			onAddEdge({
				id: `e-introduction${flowsData.scenario.id}-message${flowsData.scenario.starting_message}`,
				source: `introduction${flowsData.scenario.id}`,
				target: `message${flowsData.scenario.starting_message}`,
			});

		await Object.values(flowsData.messages).map(async (message) => {
			//setup message translations
			const mt: MessageTranslations = {};
			message.translations.map((tr) => {
				mt[flowsData.messagesTranslations[tr].product_2_languages_code] = {
					directus_id: tr,
					text: flowsData.messagesTranslations[tr].text,
					overwrite_name: flowsData.messagesTranslations[tr].overwrite_name
				};
			});

			const answers: MessageNodeData['answers'] = {};

			message.answers.map((ans) => {
				//setup answer translations
				const answerTranslations: AnswerTranslations = {};
				flowsData.answers[ans].translations.map((tr) => {
					answerTranslations[flowsData.answerTranslations[tr].product_2_languages_code] = {
						directus_id: tr,
						text: flowsData.answerTranslations[tr].text ?? '',
						feedback_title: flowsData.answerTranslations[tr].feedback_title ?? '',
						feedback_body: flowsData.answerTranslations[tr].feedback_body ?? ''
					};
				});
				//setup answers
				answers[`answer${flowsData.answers[ans].id}`] = {
					directus_id: flowsData.answers[ans].id,
					translations: answerTranslations,
					follow_up_message: flowsData.answers[ans].follow_up_message,
					correctness: flowsData.answers[ans].correctness,
					conditional_requirement: flowsData.answers[ans].conditional_requirement
				};
			});

			//add messages
			await setNodes((nodes) => [
				...nodes,
				{
					id: `message${message.id}`,
					position: message.node_data ? { x: message.node_data.positions.x, y: message.node_data.positions.y } : { x: 0, y: 0 },
					type: 'message',
					data: {
						directus_id: message.id,
						translations: mt,
						follow_up_message: message.follow_up_message,
						events: JSON.parse(message.events),
						ending: message.ending,
						message_type: message.message_type,
						answers: answers,
						speaking_character: message.speaking_character,
						reset: message.reset
					}
				} 

			]);

			//add followup edge
			if (message.follow_up_message)
				onAddEdge({
					id: `e-message${message.id}-message${message.follow_up_message}`,
					source: `message${message.id}`,
					target: `message${message.follow_up_message}`,
				});

			//add ending edge
			if (message.ending) {
				onAddEdge({
					id: `e-message${message.id}-ending${message.ending}`,
					source: `message${message.id}`,
					target: `ending${message.ending}`,
				});
			}

			//add egde for answer followups
			message.answers.map((ans) => {
				const fm = flowsData.answers[ans].follow_up_message;
			
				if (fm)	onAddEdge({
					id: `e-message${message.id}-answer${ans}-message${fm}`,
					source: `message${message.id}`,
					sourceHandle: `source-message${message.id}-answer${ans}`,
					target: `message${fm}`,
					targetHandle: `target-message${fm}`,
				});
			});
		});

		//add endings
		await Object.values(flowsData.endings).map(async (ending) => {
			//setup ending translations
			const et: EndingTranslations = {};
			ending.translations.map((tr) => {
				et[flowsData.endingsTranslations[tr].product_2_languages_code] = {
					directus_id: tr,
					description: flowsData.endingsTranslations[tr].description
				};
			});

			await setNodes((nodes) => [
				...nodes,
				{
					id: `ending${ending.id}`,
					position: ending.node_data ? { x: ending.node_data.positions.x, y: ending.node_data.positions.y } : { x: 0, y: 0 },
					type: 'ending',
					data: {
						directus_id: ending.id,
						translations: et,
						achieved: ending.achieved,
					}
				}
			]);
		});
		
		//add stickynotes
		await Object.values(flowsData.stickynotes).map(async (stickynote, index) => {

			await setNodes((nodes) => [
				...nodes,
				{
					id: `stickynote${stickynote.id}`,
					position: stickynote.node_data ? { x: stickynote.node_data.positions.x, y: stickynote.node_data.positions.y } : { x: 0, y: 0 },
					type: 'stickynote',
					data: {
						directus_id: stickynote.id,
						color: stickynote.color,
						text: stickynote.text,
						number: index+1,
						createdBy: stickynote.user_created
					}
				}
			]);
		});

		//add sceneswaps
		await Object.values(flowsData.sceneswaps).map(async (sceneswap, index) => {

			await setNodes((nodes) => [
				...nodes,
				{
					id: `sceneswap${sceneswap.id}`,
					position: sceneswap.node_data ? { x: sceneswap.node_data.positions.x, y: sceneswap.node_data.positions.y } : { x: 0, y: 0 },
					type: 'sceneswap',
					data: {
						directus_id: sceneswap.id,
						source_message_ids: sceneswap.source_message_ids,
						target_message_id: sceneswap.target_message_id,
						scene_id: sceneswap.scene_id ?? ''
					}
				}
			]);

			//add edge(s)
			sceneswap.source_message_ids.map((source_message_id) => {
				onAddEdge({
					id: `e-message${source_message_id}-sceneswap${sceneswap.id}`,
					source: `message${source_message_id}`,
					target: `sceneswap${sceneswap.id}`,
				});
			});

			if(sceneswap.target_message_id)
				onAddEdge({
					id: `e-sceneswap${index+1}-message${sceneswap.target_message_id}`,
					source: `sceneswap${sceneswap.id}`,
					target: `message${sceneswap.target_message_id}`,
				});
		});
	};

};