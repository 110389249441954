import { useAtom } from 'jotai';
import { Connection, Edge } from 'reactflow';
import { MessageNode, EndingNode, edgesAtom, IntroductionNode, SceneswapNode, GameEvent } from '../atoms/flow';
import { updateAnswerInDirectus, updateNodeInDirectus } from '../tools/directusActions/update';
import { changeOptions, useGetNodeWithId, useUpdateNodeData } from './updateNodeHooks';
import { useUpdateNodeAnswer } from './answerHooks';

export const useAddEdge = () => {
	const [, setEdges] = useAtom(edgesAtom);

	return (newEdge: Edge) => {
		setEdges((edges) => [...edges, {...newEdge, type: 'custom'}]);
	};
};

export const useConnectEdge = () => {
	const getNodeWithId = useGetNodeWithId<MessageNode | EndingNode | IntroductionNode | SceneswapNode>();
	const updateNodeAnswer = useUpdateNodeAnswer();
	const updateNodeData = useUpdateNodeData();

	return (connection: Connection) => {
		if(!connection.source || !connection.target || !connection.sourceHandle) return;
		const source = getNodeWithId(connection.source);
		const target =  getNodeWithId(connection.target);

		if(!source || !source.type || !target || !target.type) return;

		if(connection.sourceHandle.includes('answer')){//store follow_up_message of answer
			updateNodeAnswer(source.id,'answer'+connection.sourceHandle.split('answer')[1],{field: 'follow_up_message',newValue: target.data.directus_id});
		}
		else if (source.type === 'introduction'){//set starting message of scenario
			updateNodeData(source.id, {field: 'starting_message', newValue: target.data.directus_id});
		}
		else if (source.type === 'sceneswap'){//set message for in set-message events
			updateNodeData(source.id, {field: 'target_message_id', newValue: target.data.directus_id});
		}
		else if (target.type === 'sceneswap'){//store change-scene and set-message events in message
			const setMessageEvent: GameEvent[] = [{type : 'set-message',  message_id: `${target.data.target_message_id}`, direct: false, fifo: false}];
			if(source.type === 'message') updateNodeData(source.id, {field: 'events', newValue: [...source.data.events, 
				{ type : 'change-scene', scene_id : target.data.scene_id, direct: false, fifo: true},
				... target.data.target_message_id ? setMessageEvent : []
			]});
			source.data.directus_id &&	updateNodeData(target.id, {field: 'source_message_ids', newValue: [...target.data.source_message_ids, source.data.directus_id]});
		}
		else {//change field of source
			const change: {[key: string] : changeOptions} = {
				'ending' : {field: 'ending', newValue: target.data.directus_id},
				'message' : {field: 'follow_up_message', newValue: target.data.directus_id},
			};
			updateNodeData(source.id, change[target.type]);
		}
	};
};

export const useDeleteEgde = () => {
	const [, setEdges] = useAtom(edgesAtom);

	const updateNodeData = useUpdateNodeData();
	const updateNodeAnswer = useUpdateNodeAnswer();
	const getSceneswapNodeWithId = useGetNodeWithId<SceneswapNode>();
	const getMessageNodeWithId = useGetNodeWithId<MessageNode>();


	return (requestedEdge: Edge) => {
		//remove edge
		setEdges((edges) => 
			edges.filter(edge => edge.id != requestedEdge.id)
		);

		if(requestedEdge.sourceHandle?.includes('answer')){//answer edge
			//clear followup field in data package
			updateNodeAnswer(requestedEdge.source, 'answer'+requestedEdge.sourceHandle?.split('answer')[1], {field: 'follow_up_message', newValue: null});

			return updateAnswerInDirectus(parseInt(requestedEdge.sourceHandle.split('answer')[1]), {field: 'follow_up_message', newValue: null});
		}
		else if(requestedEdge.source.includes('introduction')) { //introduction edge

			updateNodeData(requestedEdge.source, {field: 'starting_message', newValue: null}, false);

			return updateNodeInDirectus('introduction', parseInt(requestedEdge.source.replace('introduction', '')),{field: 'starting_message',newValue: null});
		}
		else if(requestedEdge.target.includes('sceneswap')) { //message to sceneswap edge
			const node = getSceneswapNodeWithId(requestedEdge.target);
			const filteredIDs = node ? node.data.source_message_ids.filter((i) => i != parseInt(requestedEdge.source.replace('message', ''))) : [];

			const sourceNode = getMessageNodeWithId(requestedEdge.source);
			sourceNode && updateNodeData(requestedEdge.source, {field: 'events', newValue: sourceNode.data.events.filter((e) => e.type != 'change-scene' && e.type != 'set-message')});

			updateNodeData(requestedEdge.target, {field: 'source_message_ids', newValue: filteredIDs}, false);

			return updateNodeInDirectus('sceneswap', parseInt(requestedEdge.target.replace('sceneswap', '')),{field: 'source_message_ids',newValue: filteredIDs});
		}
		else if(requestedEdge.source.includes('sceneswap')) {//sceneswap to message edge
			updateNodeData(requestedEdge.source, {field: 'target_message_id', newValue: null}, false);

			return updateNodeInDirectus('sceneswap', parseInt(requestedEdge.source.replace('sceneswap', '')),{field: 'target_message_id',newValue: null});
		}
		else {//message edge
			const targetFieldType = requestedEdge.target.includes('ending') ? 'ending' : 'follow_up_message';

			updateNodeData(requestedEdge.source, {field: targetFieldType, newValue: null}, false);

			return updateNodeInDirectus('message', parseInt(requestedEdge.source.replace('message', '')),{field: targetFieldType,newValue: null});
		}

	};
};

export const useUpdateEdgeSelection = () => {
	const [, setEdges] = useAtom(edgesAtom);

	return (edgeId: string, selected: boolean) => {
		setEdges((edges) => edges.map(edge => {
			if(edge.id === edgeId) edge.selected = selected;
			return edge;
		}));
	};
};