import { useAtomValue } from 'jotai';
import { ObjectEntries } from '../../../atoms/atoms';
import { ScenarioSettings } from '../../../atoms/menu';
import { InputBox, Label } from './EditScenarioWindow';
import { editorDataAtom } from '../../../atoms/editor';
import styled from 'styled-components';

const EditEnvironment = ({scenarioSettings, changeScenarioSetting}:{scenarioSettings: ScenarioSettings, changeScenarioSetting: (change: ObjectEntries<ScenarioSettings>) => void}) => {
	const editorData = useAtomValue(editorDataAtom);

	return (
		<>
			<Label>Environment</Label>
			<InputBox>
				<StyledSelect 
					value={scenarioSettings.environment === null ? '' : scenarioSettings.environment} 
					onChange={(e) => changeScenarioSetting({field: 'environment', newValue: !e.target.value ? null : e.target.value})}
				>
					<StyledOption value={''}>Select the environment...</StyledOption>

					{editorData && Object.values(editorData.environments).map((env) => {
						return (
							<StyledOption key={`option-${env.id}`} value={env.id}>
								{env.title}
							</StyledOption>
						);
					})}
				</StyledSelect>
			</InputBox>
		</>
	);
};

const StyledSelect = styled.select`
    background: none;
    border: none;
    outline: none;
    width: 100%;
	cursor: pointer;
`;
 
const StyledOption = styled.option`

`;
export default EditEnvironment;