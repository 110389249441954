import { useAtomValue } from 'jotai';
import { flowDataAtom } from '../../../../atoms/flow';
import { ChangeEvent, useMemo } from 'react';
import { activeLanguageAtom } from '../../../../atoms/atoms';
import { Character, CharacterTranslation, editorDataAtom } from '../../../../atoms/editor';

const CharactersDropdown = ({playerOption, value, onSelectChange}: CharacterDropdownProps) => {
	const flowsData = useAtomValue(flowDataAtom);
	const editorData = useAtomValue(editorDataAtom);
	const activeLanguage = useAtomValue(activeLanguageAtom);

	// process character data for use in dropdowns
	const characters = useMemo(() => {
		const newCharacters : {[characterId: string]: CharacterEntry} = {};
			
		flowsData && Object.values(flowsData.scenario.characters).map((id) => {
			const character = editorData?.characters[id];
			if(!character) return;
			const charactersTranslations: {[language: string]: CharacterTranslation} = {};
			character.translations.map((t) => charactersTranslations[editorData.characters_translations[t].product_2_languages_code] = editorData.characters_translations[t]);	
			newCharacters[id] = {data: character, translation: charactersTranslations[activeLanguage] ?? {name: 'no translation'}};
		});
	
		return newCharacters;
	},[flowsData, activeLanguage, editorData]);

    
	return ( 
		<select value={value} onChange={(e) => onSelectChange(e)}>
			{playerOption && <option value={'null'}>player</option>}
			{characters && Object.values(characters).map((character) => 
				<option key={`node-editor-message-speakingCharacter-option-${character.data.id}`} value={character.data.id}>
					{character.translation.name}
				</option>
			)}
		</select>
	);
};

type CharacterDropdownProps = {
    playerOption?: boolean
    value: number,
    onSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void
}


type CharacterEntry = {
	data: Character, 
	translation: CharacterTranslation
}
export default CharactersDropdown;