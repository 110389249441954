import { Connection, Edge, EdgeChange, NodeChange } from 'reactflow';
import { useDeleteNode,  useUpdateNodeValues } from './updateNodeHooks';
import { useAddEdge, useDeleteEgde, useUpdateEdgeSelection } from './edgeHooks';
import { updateNodeInDirectus } from '../tools/directusActions/update';
import { useAddNode } from './addNodeHooks';
import { NodeType, nodesAtom } from '../atoms/flow';
import { useAtom } from 'jotai';

export const useOnNodesChange = () => {
	const updateNodeValues = useUpdateNodeValues();
	const deleteNode = useDeleteNode();

	return (changes: NodeChange[]) => {
		changes.forEach(change => {
			switch (change.type) {
			case 'select':
				updateNodeValues(change.id, {field: 'selected', newValue: change.selected});
				break;
			case 'position':
				if (!change.position) return;
				updateNodeValues(change.id, {field: 'position', newValue: change.position});
				break;
			case 'remove':
				deleteNode(change.id);
				break;
			}
		});
	};

};

export const useOnNodeDragStop = () => {
	const [nodes] = useAtom(nodesAtom);

	return () => {
		nodes.map((node) => {
			if(node.selected && node.type && node.data.directus_id)
				updateNodeInDirectus(node.type, node.data.directus_id, {field: 'node_data', newValue: {
					positions: {
						x: node.position.x,
						y: node.position.y
					}
				}}); //only update directus when stopped draging to reduce calls
		});
	};
};

export const useOnEdgesChange = () => {
	const updateEdgeSelection = useUpdateEdgeSelection();

	return (changes: EdgeChange[]) => {
		changes.forEach(change => {
			switch (change.type) {
			case 'select':
				updateEdgeSelection(change.id, change.selected);
				break;
			}
		});
	};
};

export const useOnEdgesDelete = () => {
	const deleteEdge = useDeleteEgde();

	return (edges: Edge[]) => {
		if (edges[0].selected === true)//also fires when deleting nodes, so check if edge is selected
			deleteEdge(edges[0]);
	};
};


export const useOnConnectEnd = () => {
	const addNode = useAddNode();

	return async (event: MouseEvent | TouchEvent) => {
		const positions = event as MouseEvent;
		addNode('message', {x: positions.clientX, y: positions.clientY});
	};
};

export const useOnConnect = () => {
	const addEdge = useAddEdge();

	return (newEdge: Edge | Connection) => {
		const edge = newEdge as Edge;
		addEdge({ ...edge, id: `e-${edge.source}-${edge.target}` });
	};
};


export const useOnDrop = () => {
	const addNode = useAddNode();

	return (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		const type = event.dataTransfer.getData('application/reactflow') as NodeType | 'context';
		addNode(type, {x: event.clientX, y: event.clientY});
	};
};

export const useOnDragOver = () => {

	return (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	};
};