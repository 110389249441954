import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { OnSelectionChangeFunc, useOnSelectionChange} from 'reactflow';
import styled from 'styled-components';
import MessageEditWindow from './MessageEditWindow/MessageEditWindow';
import EndingEditWindow from './EndingEditWindow/EndingEditWindow';
import { nodesAtom, NodeType } from '../../atoms/flow';
import StickynoteEditWindow from '../../Editor/nodes/StickynoteNode/StickynoteEditWindow/StickynoteEditWindow';
import SceneswapEditWindow from './SceneswapEditWindow/SceneswapEditWindow';

const editors: {[key in NodeType]?: any} = {
	'message': MessageEditWindow,
	'ending' : EndingEditWindow,
	'stickynote' : StickynoteEditWindow,
	'sceneswap' : SceneswapEditWindow
};

const EditWindow = () => {

	const nodes = useAtomValue(nodesAtom);
	const [selectedNodeId, setSelectedNodeId] = useState<string>();

	const onSelectionChange : OnSelectionChangeFunc = ({nodes}) => {
		if(nodes.length !== 1) setSelectedNodeId(undefined);
		else setSelectedNodeId(nodes[0].id);
	};

	const selectedNode = useMemo(() => {
		const filtered = nodes.filter((node) => node.id === selectedNodeId);
		if(filtered.length === 0 || (filtered[0].type && !editors[filtered[0].type])) return undefined;
		else return filtered[0];
	},[selectedNodeId]);

	useOnSelectionChange({onChange: onSelectionChange});

	const SpecificEditor = useMemo(() => {
		if(!selectedNode || !selectedNode.type) return;
		else return editors[selectedNode.type];
	},[selectedNode]);

	return ( 
		<>
			{selectedNode && 
				<Container>
					<Content>
						{ SpecificEditor && <SpecificEditor selectedNodeId={selectedNodeId}/> }
					</Content>
				</Container>
			}
		</>
	);
};

// sc 

const Container = styled.div`


    position: absolute;
	right: 0;
	max-height: 90vh;
	
    width: 400px;

	display:flex;
	flex-direction:column;
	justify-content: flex-end;
`;

const Content = styled.div`

	pointer-events: all;

	overflow-y: auto;

    border-radius: ${p =>p.theme.styles.borderRadius}px;
    background-color:  ${p => p.theme.colors.neutralDark};
	padding: ${p => p.theme.styles.padding}px;
	color: ${p => p.theme.colors.neutralLightest};
`;

// types

export type NodeEditorProps = {
	selectedNodeId: string;
}

export default EditWindow;