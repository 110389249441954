import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Editor from './pages/Editor';
import Logout from './pages/Logout';

const App = () => {

	return (
		<BrowserRouter>
			<Routes>
				<Route path={'/*'} element={
					<Editor/>
				}/>
				<Route path={'/login'} element={
					<Login/>
				}/>
				<Route path={'/logout'} element={
					<Logout/>
				}/>
				<Route path={'*'} element={<Navigate to="/" replace/>} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
