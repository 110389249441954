import { useAtom, useAtomValue } from 'jotai';
import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import { activeLanguageAtom } from '../../../atoms/atoms';
import LanguageOption from './Option';
import { flowDataAtom } from '../../../atoms/flow';

const LanguageSelect = (props: HTMLAttributes<HTMLDivElement>) => {
	const flowsData = useAtomValue(flowDataAtom);
	const [language, setLanguage] = useAtom(activeLanguageAtom);

	return (
		<Container {...props}>
			<Select style={{pointerEvents: 'all', ...props.style}} value={language} onChange={(e) => setLanguage(e.currentTarget.value)} >
				{flowsData && flowsData.scenario.languages && flowsData.scenario.languages.map((value) => 
					<LanguageOption key={`lng-${value}`} value={value}/>
				)}
			</Select>
		</Container>
	);
};

const Container = styled.div`
	background-color: ${p => p.theme.colors.neutralLightest};
	border-radius: 25px;
	padding: ${p => p.theme.styles.padding/2}px;
	font-weight: bold;
	border: none;
	outline: none;
	box-shadow: ${p => p.theme.styles.boxShadow};
`;

const Select = styled.select`
    color: ${p => p.theme.colors.primary};
	background-color: ${p => p.theme.colors.neutralLightest};
	font-weight: bold;
	border: none;
	outline: none;
	text-transform: uppercase;

`;
 
 
export default LanguageSelect;