import styled, { useTheme } from 'styled-components';
import { Answer } from '../../../../Editor/nodes/MessageNode/MessageNode';
import { PiSmileyBold, PiSmileyMehBold, PiSmileySadBold } from 'react-icons/pi';

const CorrectnessField = ({correctness, onChangeCorrectness} : CorrectnessFieldProps) => {

	const theme = useTheme();

	return (  
		<Container $correctness={correctness}>
			<PiSmileySadBold color={theme.colors.danger} onClick={() => onChangeCorrectness(0)}/>
			<PiSmileyMehBold color={theme.colors.alert} onClick={() => onChangeCorrectness(1)}/>
			<PiSmileyBold  color={theme.colors.correct} onClick={() => onChangeCorrectness(2)}/>
		</Container>
	);
};

// sc

const Container = styled.div<{$correctness: Answer['correctness']}>`
  	& > svg {
		cursor: pointer;
		font-size: 2em;
		filter: grayscale(1);
	background-color: ${p => p.theme.colors.neutralLightest};

	}

	& > :nth-child(${p => p.$correctness !== null && p.$correctness + 1}) {
		border-block-end: solid 3px ${p => p.theme.colors.primary};
		filter: none;
	}
`;

// type

type CorrectnessFieldProps = {
	correctness: Answer['correctness'],
	onChangeCorrectness: (correctness: Answer['correctness']) => void
};

export default CorrectnessField;