import { useContext, useMemo } from 'react';
import { useGetNodeWithId, useUpdateNodeData } from '../../../../hooks/updateNodeHooks';
import { NodeEditorProps } from '../../../../Interface/EditWindow/EditWindow';
import { NodesEditorContainer as Container } from '../../../../Interface/EditWindow/EditWindowContainer';
import { StickynoteNode } from '../../../../atoms/flow';
import styled, { ThemeContext } from 'styled-components';

const StickynoteEditWindow = ({selectedNodeId}: NodeEditorProps) => {

	const updateNode = useUpdateNodeData();
	const themeContext = useContext(ThemeContext);


	const getNodeWithId = useGetNodeWithId<StickynoteNode>();

	const node = useMemo(() => {
		return getNodeWithId(selectedNodeId);
	},[selectedNodeId, getNodeWithId]);

	const onChangeColor = (color: string) => {
		if(!node) return;

		updateNode(selectedNodeId, {field: 'color', newValue: color} );	
	};

	return (  
		<Container>
			<Icons>
				{themeContext?.note_colors.map((color: string) => {
					return <Dot selected={color === node?.data.color} key={`color-${color}`} onClick={() => onChangeColor(color)} style={{backgroundColor: color}}/>;
				})}
			</Icons>
		</Container>
	);
};

const Dot = styled.div<{selected: boolean}>`
	width: 20px;
	aspect-ratio: 1/1;
	border-radius: 100%;
	cursor: pointer;
	border: ${p => p.selected ?  `2px solid ${p.theme.colors.neutral}`: 'none'};
`;


const Icons = styled.div`
	display: flex;
	gap: 5px;
	margin-bottom: 5px;
`;
 
 
export default StickynoteEditWindow;