import { useAtom } from 'jotai';
import { HTMLAttributes } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { focusedWindowAtom } from './Focused';

export const FocusedWindow = (props: WindowProps) => {
	const [, setFousedWindow] = useAtom(focusedWindowAtom);

	return (
		<Container {...props}>
			<HeaderRow>
				<IoCloseOutline visibility={'hidden'} />
				<h1>{props.title ?? 'unnamed window'}</h1>
				<IoCloseOutline onClick={() => setFousedWindow(undefined)}/>
			</HeaderRow>
			<Content {...props.$innerContainerProps}>
				{props.children}
			</Content>
			{props.buttons && <ButtonsContainer>
				{props.buttons}
			</ButtonsContainer>}
		</Container>
	);
};

const Container = styled.div`
	margin: auto;
	width: 33.3%;
	max-height: 90%;

	padding: ${p => p.theme.styles.padding*2}px;
	border-radius: ${p => p.theme.styles.borderRadius}px;
	background-color: ${p => p.theme.colors.neutralLightest};

	display: flex;
	flex-direction: column;	
`;

const HeaderRow = styled.div`

	margin-block-end: ${p => p.theme.styles.padding}px;
	margin-inline-start: .5em;

	display: flex;
	align-items: center;
	justify-content: space-between;

	gap: ${p => p.theme.styles.padding}px;

	& > h3 {
		margin: 0;
	}
	& > svg {
		font-size: 2em;
		translate: 0 -50%;
		cursor: pointer;
	}
`;

const Content = styled.div`
	padding: ${p => p.theme.styles.padding}px;
	border-radius: ${p => p.theme.styles.borderRadius}px;
	background-color: ${p => p.theme.colors.neutralLightest};

	max-height: 100%;
	overflow-y: auto;
`;

const ButtonsContainer = styled.div`
	bottom: 10%;
	background-color: ${p => p.theme.colors.neutralLightest};
	width: 100%;

	display: flex;
	justify-content: center;
	gap: ${p => p.theme.spacing.gap}px;

	padding: ${p => p.theme.styles.padding*2}px;
	& > button {
		flex-grow: 1;
	}
`;


// types

type WindowProps = HTMLAttributes<HTMLDivElement> & {
	buttons?: JSX.Element;
	title?: string;
	$innerContainerProps?: HTMLAttributes<HTMLDivElement>
}