import { useAtom, useAtomValue } from 'jotai';
import { focusedWindowAtom } from '../Focused';
import { FocusedWindow } from '../FocusedWindow';
import { Tile } from '../../Menu/Tile';
import styled, { ThemeContext } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { ScenarioSettings } from '../../../atoms/menu';
import { ObjectEntries } from '../../../atoms/atoms';
import { editorDataAtom, selectedScenarioIdAtom } from '../../../atoms/editor';
import empty_env from '../../../assets/images/empty_env.jpg';
import EditName from './EditName';
import EditLanguages from './EditLanguages';
import EditEnvironment from './EditEnvironment';
import EditCharacters from './EditCharacters';
import { Button } from '../../Interactables/Buttons';
import { createSceanrioInDirectus } from '../../../tools/directusActions/create';
import { updateScenarioInDirectus } from '../../../tools/directusActions/update';
import { flowDataAtom } from '../../../atoms/flow';

const EditScenarioWindow = () => {
	const [, setFousedWindow] = useAtom(focusedWindowAtom);
	const editorData = useAtomValue(editorDataAtom);
	const [flowsData, setFlowsData] = useAtom(flowDataAtom);
	const themeContext = useContext(ThemeContext);

	const [selectedScenarioId, setSelectedScenarioId] = useAtom(selectedScenarioIdAtom);
	const [saving, setSaving] = useState(false);

	const [scenarioSettings, setScenarioSettings] = useState<ScenarioSettings>({
		name: '',
		characters: [],
		environment: null,
		languages: ['en', 'nl']
	});

	useEffect(() => {
		if(selectedScenarioId && flowsData && editorData){
			setScenarioSettings({
				name: flowsData.scenario.name,
				characters: flowsData.scenario.characters,
				environment: flowsData.scenario.environment,
				languages: flowsData.scenario.languages
			});
		}
	}, []);

	const changeScenarioSetting = (change: ObjectEntries<ScenarioSettings>) => {
		setScenarioSettings((settings) => {
			settings = {...settings, [change.field]: change.newValue};
			return settings;
		});
	};

	const onSave = () => {
		new Promise((resolve) => {
			setSaving(true);
			if(selectedScenarioId){//update settings in canvas
				updateScenarioInDirectus(selectedScenarioId, scenarioSettings).then(() => {
					resolve(selectedScenarioId);
				}).then(() => {
					setFlowsData((flowData) => {
						const newFlowData : any = {};
						flowData && Object.entries(flowData).map(([key, values]) => {
							if(key === 'scenario')	newFlowData['scenario'] = {...values, ...scenarioSettings};
							else newFlowData[key] = values;
						});
						return newFlowData;
					});
				});
			} else { //create new one in menu
				createSceanrioInDirectus(scenarioSettings).then((request: any) => {
					resolve(request.id);
				});
			}
		}).then((result: any) => {
			setSelectedScenarioId(result);
			setSaving(false);
			setFousedWindow(undefined);
		});
	};

	return ( 
		<FocusedWindow 
			buttons={<>
				<Button type='outlined' onClick={() => setFousedWindow(undefined)} >cancel</Button>
				<Button onClick={onSave} style={{backgroundColor: themeContext?.colors.correct}}>save</Button>
			</>}
			title={selectedScenarioId ? 'Edit scenario' :'Create scenario'}
		>
			{saving ? 'Saving...'
				:
				<Container>
					<StyledTile $background={scenarioSettings.environment ? `https://tqwi.directus.app/assets/${scenarioSettings.environment}` : empty_env}>
						{!scenarioSettings.name ? 'Scenario name' : scenarioSettings.name}
					</StyledTile>

					<EditName scenarioSettings={scenarioSettings} changeScenarioSetting={changeScenarioSetting}/>
					<EditEnvironment scenarioSettings={scenarioSettings} changeScenarioSetting={changeScenarioSetting}/>
					<EditLanguages scenarioSettings={scenarioSettings} changeScenarioSetting={changeScenarioSetting}/>
					<EditCharacters scenarioSettings={scenarioSettings} changeScenarioSetting={changeScenarioSetting}/>
				</Container>
			}
		</FocusedWindow> 
	);
};

const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${p => p.theme.spacing.gap/2}px;
`;

const StyledTile = styled(Tile)<{$background: string}>`

	background-image: url(${p => p.$background});
	background-size: cover;

	justify-content: flex-end;
    color: ${p => p.theme.colors.neutralLightest};
	cursor: auto;
	width: 50%;
`;


export const InputBox = styled.div`
	width: 100%;
	border-radius: 25px;
	background-color: ${p => p.theme.colors.neutralLighter};
	padding: ${p => p.theme.styles.padding}px;

`;

export const Label = styled.div`
	width: 100%;
	text-align: left;
	font-weight: bold;
`;


export default EditScenarioWindow;