import styled from 'styled-components';
import { Label } from '../../../misc/Label';

export const TextField = styled(Label)`
	display: flex;
	flex-direction: column;
	align-items: stretch;

	& > textarea {
		font-family: 'Poppins';
		font-size: .8em;
		font-weight: 200;
	}
`;
