import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import retrieveGlobalData from '../data/retrieve/editorData';
import { editorDataAtom } from '../atoms/editor';
import Canvas from '../Editor/Canvas';
import Interface from '../Interface/Interface';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { checkDirectusAuthentication } from '../tools/authentication';
import Redirect from '../Redirect';

const Editor = () => {

	const setGlobalData = useSetAtom(editorDataAtom);
	const navigate = useNavigate();

	const [authenticated, setAuthenticated] = useState<boolean>(false);

	const initialize = async () => {
		await checkDirectusAuthentication().then(async () => {
			setGlobalData(await retrieveGlobalData());
			setAuthenticated(true);
		}).catch(() => navigate('/login'));
	};

	useEffect(() => {
		initialize();
	}, []);

	return (
		<>
			{authenticated &&
				<Routes>
					<Route index element={
						<>
							<Canvas />
							<Interface />
						</>
					}/>
					<Route path="/:scenarioId" element={<Redirect/>} />
				</Routes>
			}
		</>
	);
};

export default Editor;