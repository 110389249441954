import styled from 'styled-components';
import logo from '../../assets/logo.png';
import ScenarioName from './ScenarioName';
import Options from './Options';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { selectedScenarioIdAtom } from '../../atoms/editor';
import { searchedScenarioAtom } from '../../atoms/menu';
import { CiSearch } from 'react-icons/ci';
import { useReactFlow } from 'reactflow';
import { enableMessageNumbersAtom } from '../../atoms/atoms';
import { Input } from '../Interactables/Input';


const HeaderBar = () => {
	const {setViewport, getNodes} = useReactFlow();
	const [, setMessageNumbersEnabled] = useAtom(enableMessageNumbersAtom);

	const [openOptions, setOpenOptions] = useState(false);
	const [selectedScenarioId, setSelectedScenarioId] = useAtom(selectedScenarioIdAtom);
	const [,setSearchedScenario] = useAtom(searchedScenarioAtom);
	const [searched, setSearched] = useState('');

	useEffect(() => {
		setOpenOptions(false);
	}, [selectedScenarioId]);

	useEffect(() => {
		if(!selectedScenarioId)
			setSearchedScenario(searched);
		else
			searchMessageId(parseInt(searched));
	}, [searched]);

	const searchMessageId = (number: number) => {
		const node = getNodes().find((n) => n.data.directus_id === number);
		if(!node) return;
		setMessageNumbersEnabled(true);
		setViewport({ x: -(node.position.x-900), y: -(node.position.y-400), zoom: 1}, { duration: 800 });
	};

	return ( 
		<Container>
			<Logo style={{cursor: 'pointer'}} onClick={() => setSelectedScenarioId(undefined)}/>
			{selectedScenarioId && <ScenarioName toggleOptions={() => setOpenOptions(!openOptions)}/>}
			{openOptions && <Options/>}
			<InputContainer>
				<StyledInput
					placeholder={`Search ${selectedScenarioId ? 'message id' : 'scenario'}`} 
					type='text' 
					value={searched} 
					onChange={(e) => setSearched(e.target.value)}
				/>
				<CiSearch />
			</InputContainer>
		</Container>
	);
};

// sc

const Container = styled.div`
	pointer-events: all;
	height: 60px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${p => p.theme.colors.neutralLightest};
	padding: ${p => p.theme.spacing.gap / 2}px;
`;


const Logo = styled.div`
	/* height: 100%; */
	width: 120px;
	left: ${p => p.theme.styles.padding}px;
	aspect-ratio: 3/1;
	position: absolute;

	background-image: url(${logo});
	background-size: cover;
`;

const InputContainer = styled.div`
	position: absolute;
	right: ${p => p.theme.styles.padding}px;
	width: 350px;
	border-radius: 25px;
	background-color: ${p => p.theme.colors.neutralLighter};
	display: flex;
	align-items: center;
	padding: ${p => p.theme.styles.padding}px;
`;

const StyledInput = styled(Input)`
	flex-grow: 1;
`;

 
export default HeaderBar;