import { createGlobalStyle } from 'styled-components';
import { fonts } from './fonts/fonts';

export const GlobalStyle = createGlobalStyle`

	${fonts}

	html, body, #root {
		height: 100%;
		margin: 0;
		padding:0;
		font-family: 'Poppins', sans-serif;
		overflow: hidden;
		font-size: 16px;
		background-color: ${p => p.theme.colors.neutralLightest};
	}

	* {
		box-sizing: border-box;
		user-select: none;
		-webkit-user-select: none;
	}

	h1, h2, h3 {
		margin: 0;
	}

	h2 {

	}

	h3 {
		font-weight: 400;
		font-size: 13px;
	}

	//reactflow

	& .react-flow__attribution {
		display: none;
	}
	.react-flow__edge-path {
		stroke-width: 5px!important;
	}

	.react-flow__handle-connecting {
		background: ${p => p.theme.colors.danger};
	}
	.react-flow__handle-valid {
			background: ${p => p.theme.colors.correct};
			color: ${p => p.theme.colors.neutralLightest};
	}
`;