import { useAtom } from 'jotai';
import styled from 'styled-components';
import { Tile } from './Tile';
import Markdown from 'react-markdown';
import { focusedWindowAtom } from '../FocusedWindows/Focused';

const CreateScenarioTile = () => {

	const [, setFocusedWindow] = useAtom(focusedWindowAtom);

	const createScenario = () => { 
		setFocusedWindow('editScenario');
	};

	return ( 
		<Container id='createtile' onClick={createScenario}>
			<StyledMarkdown>{'&#x2b; \n\nnew scenario'}</StyledMarkdown>
		</Container>
	);
};

const Container = styled(Tile)`
    border: dashed 2px ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.primary};
    justify-content: center;
    align-items: center;
`;

const StyledMarkdown = styled(Markdown)`
    text-align: center;
    & > :first-child {
        font-size: 5em;
        margin: 0;
	}
`;
 
export default CreateScenarioTile;