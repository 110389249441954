import { atom, useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import LoadingWindow from './LoadingWindow';
import Error from './ErrorWindow';
import EditScenarioWindow from './EditScenario/EditScenarioWindow';

const windows = {
	'loading': LoadingWindow,
	'error': Error,
	'editScenario': EditScenarioWindow,
};

type windowsKeys = keyof typeof windows;

export const focusedWindowAtom = atom<windowsKeys | undefined>(undefined);
export const errorsAtom = atom<string | undefined>(undefined);

// This component is meant to alway be on top.
const Focused = () => {

	const [focusedWindow,setFocusedWindow] = useAtom(focusedWindowAtom);
	const [,setError] = useAtom(errorsAtom);

	useEffect(() => {
		window.addEventListener('directusError', (e: any) => {	
			setFocusedWindow('error');
			setError(e.detail);
		});
	}, []);

	const Window = useMemo(() => {
		if (!focusedWindow) return undefined;

		return windows[focusedWindow];
	}, [focusedWindow]);


	return (
		<>
			{Window &&
				<Container>
					<Window />
				</Container>
			}
		</>
	);
};

// sc

const Container = styled.div`
	
	position: absolute;
	inset:0;
	z-index: 10;

	padding: ${p => p.theme.styles.padding}px;
	backdrop-filter: blur(4px);

	background-color: hsla(0,0%,0%,.5);

	pointer-events: all;

	display: flex;
`;

export default Focused;