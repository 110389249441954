import { useEffect, useMemo, useState } from 'react';
import { Scenario } from '../../atoms/menu';
import styled, { css } from 'styled-components';
import { IoArrowBackOutline, IoArrowForward } from 'react-icons/io5';
import { Button } from '../Interactables/Buttons';
 
const Pagination = ({items, pageLimit, setPageItems} : {items: Scenario[], pageLimit: number,setPageItems: (val: Scenario[]) => void}) => {
	const [pageNumber, setPageNumber] = useState(0);
	const pageCount = useMemo(() => {
		setPageNumber(0);
		return Math.ceil(items.length / pageLimit);
	},[items]);

	const pageData = () => {
		const s = pageNumber * pageLimit;
		const e = s + pageLimit;
		return items.slice(s, e);
	};

	const nextPage = (jump: boolean) => {
		setPageNumber(Math.min(pageNumber + (jump ? 5 : 1), pageCount - 1));
	};

	const previousPage = (jump: boolean) => {
		setPageNumber(Math.max(pageNumber - (jump ? 5 : 1), 0));
	};

	useEffect(() => {
		setPageItems(pageData());
	}, [pageNumber, items]);
  
	return (
		<Container>
			<IoArrowBackOutline onClick={() => previousPage(false)}/>
			{[...Array(pageCount)].map((dot,index) => {
				dot;
				return (
					<Dot
						key={`dot-${index}`}
						onClick={() => setPageNumber(index)}
						selected={pageNumber === index}>
						{index+1}
					</Dot>
				);
			})}
			<IoArrowForward onClick={() => nextPage(false)}/>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: center;
	& > svg {
	cursor: pointer;
	color: ${p => p.theme.colors.primary};
	}
	margin-top: auto;
`;

const Dot = styled(Button)<{selected: boolean}>`
	background: none;
	font-weight: normal;
	${p => p.selected && css`
	    background-color: ${p => p.theme.colors.primaryLighter};
		color: ${p => p.theme.colors.primary};
	`}

	color: black;
	padding:0;
    height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	aspect-ratio: 1/1;
	border-radius: 100%;

	&:hover{
		color: ${p => p.theme.colors.primary};
	}
`;

export default Pagination;