import {  catchDirectusError, directus } from '../directus';
import { createItem } from '@directus/sdk';
import { AnswerTranslations, MessageNodeData, MessageTranslations } from '../../Editor/nodes/MessageNode/MessageNode';
import { EndingNode, MessageNode, StickynoteNode, SceneswapNode } from '../../atoms/flow';
import { EndingTranslations } from '../../Editor/nodes/EndingNode/EndingNode';
import { IntroductionTranslations } from '../../Editor/nodes/IntroductionNode/IntroductionNode';
import { ScenarioSettings } from '../../atoms/menu';

export const createMessageInDirectus = (newNode: MessageNode, scenarioId: number) => {

	return directus.request(createItem('product_2_dialogues_messages', {
		scenario_id: scenarioId,
		follow_up_message: newNode.data.follow_up_message,
		ending: newNode.data.ending,
		node_data: {positions: newNode.position},
		message_type: newNode.data.message_type
	})).catch((err: any) => {
		catchDirectusError('create message', err);
	});
};

export const createSceanrioInDirectus = (data: ScenarioSettings) => {

	return directus.request(createItem('product_2_scenarios', {
		...data, environment: data.environment ? data.environment : null
	})).catch((err: any) => {
		catchDirectusError('create scenario', err);
	});
};

export const createScenarioJunctionCharactersInDirectus = (scenarioId: number, characterId: number) => {
	return directus.request(createItem('product_2_scenarios_product_2_characters', {
		product_2_scenarios_id: scenarioId,
		product_2_characters_id: characterId
	})).catch((err: any) => {
		catchDirectusError('create scenario junction character', err);
	});
};

export const createStickynoteInDirectus = (newNode: StickynoteNode, scenarioId: number) => {

	return directus.request(createItem('product_2_stickynotes', {
		scenario_id: scenarioId,
		color: newNode.data.color,
		node_data: {positions: newNode.position}
	})).catch((err: any) => {
		catchDirectusError('create message', err);
	});
};

export const createSceneswapInDirectus = async (newNode: SceneswapNode, scenarioId: number) => {
	return directus.request(createItem('product_2_scene_swaps', {
		scenario_id: scenarioId,
		node_data: {positions: newNode.position}
	})).catch((err: any) => {
		catchDirectusError('create message', err);
	});
};


export const createEndingInDirectus = (newNode: EndingNode, scenarioId: number) => {	

	return directus.request(createItem('product_2_scenarios_endings', {
		achieved: false,
		scenario_id: scenarioId,
		node_data: {positions: newNode.position}
	})).catch((err: any) => {
		catchDirectusError('create ending', err);
	});
};

export const createAnswerInDirectus = (nodeData: MessageNodeData) => {
	return directus.request(createItem('product_2_dialogues_messages_answers', {
		message_id: nodeData.directus_id,
		follow_up_message: null,
		correctness: null,
	})).catch((err: any) => {
		catchDirectusError('create answer', err);
	});
};

export const createMessageTranslationInDirectus = (messageId: number, newData: MessageTranslations, language: string) => {

	return directus.request(createItem('product_2_dialogues_messages_translations', {
		product_2_dialogues_messages_id: messageId,
		product_2_languages_code: language,
		text: newData[language].text,
		overwrite_name: newData[language].overwrite_name,
	})).catch((err: any) => {
		catchDirectusError('create message translation', err);
	});
};

export const createEndingTranslationsInDirectus = (endingsId: number, newData: EndingTranslations, language: string) => {

	return directus.request(createItem('product_2_scenarios_endings_translations', {
		product_2_languages_code: language,
		product_2_scenarios_endings_id: endingsId,		
		description: newData[language].description,
	})).catch((err: any) => {
		catchDirectusError('create ending translation', err);
	});
};


export const createAnswerTranslationInDirectus = (answerId: number, newData: AnswerTranslations, language: string) => {

	return directus.request(createItem('product_2_dialogues_messages_answers_translations', {
		product_2_dialogues_messages_answers_id: answerId,
		product_2_languages_code: language,
		text: newData[language].text,
		feedback_title: newData[language].feedback_title,
		feedback_body: newData[language].feedback_body,
	})).catch((err: any) => {
		catchDirectusError('create answer translation', err);
	});
};

export const createScenarioTranslationInDirectus = (scenarioId: number, newData: IntroductionTranslations, language: string) => {

	return directus.request(createItem('product_2_scenarios_translations', {
		product_2_scenarios_id: scenarioId,
		product_2_languages_code: language,
		name: newData[language].name,
		description: newData[language].description,
		ellie_description: newData[language].ellie_description,
	})).catch((err: any) => {
		catchDirectusError('create scenario translation', err);
	});
};