import { useEffect, useMemo, useState } from 'react';
import { useGetNodeWithId, useUpdateNodeData } from '../../../hooks/updateNodeHooks';
import { Label } from '../../misc/Label';
import { NodeEditorProps } from '../EditWindow';
import { NodesEditorContainer as Container } from '../EditWindowContainer';
import { TextField } from '../MessageEditWindow/components/TextField';
import { useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../atoms/atoms';
import { EndingNode } from '../../../atoms/flow';


const EndingEditWindow = ({selectedNodeId}: NodeEditorProps) => {

	const updateNode = useUpdateNodeData();
	const getNodeWithId = useGetNodeWithId<EndingNode>();
	const language = useAtomValue(activeLanguageAtom); 

	const [text, setText] = useState<string>('');

	const node = useMemo(() => {
		return getNodeWithId(selectedNodeId);
	},[selectedNodeId, getNodeWithId]);

	useEffect(() => {
		if(!node || !language) return;
		setText(node.data.translations[language] ? node.data.translations[language].description : '');
	}, [node, language]);
	

	const onTextBlur = () => {
		if(!node) return;
		const updatedTranslations = {...node.data.translations};
		if(updatedTranslations[language])
			updatedTranslations[language] = {directus_id: updatedTranslations[language].directus_id, description: text};
		else
			updatedTranslations[language] = {directus_id: null, description: text};
		updateNode(selectedNodeId, {field: 'translations', newValue: updatedTranslations} );	
	};

	return (  
		<Container>
			<Label>Completes scenario
				<input 
					type={'checkbox'}
					onChange={(e) => {updateNode(selectedNodeId, {field: 'achieved', newValue: e.target.checked});}}
					checked={node ? node.data.achieved : false}
				/>
			</Label>
			<TextField>
				Ending text
				<textarea
					placeholder={'ending text'}
					rows={2}
					onBlur={onTextBlur}
					onChange={(e) => setText(e.target.value)}
					value={text}
				/>
			</TextField>
		</Container>
	);
};
 
export default EndingEditWindow;