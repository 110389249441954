import { readFiles, readItem, readItems, readUsers } from '@directus/sdk';
import { Schema, catchDirectusError, directus } from '../directus';

/** retrieves items from given collection
	 * @param collection name of the collection to read
	 * @param filter for querying 
	 * @param fields array of fields to read
	 * @param deep for querying or searching within relation fields
*/

export const retrieveItemsFromCollection = async (collection: keyof Schema, params: {filter?: any, fields?: string[], deep?: any}) => {
	let itemsInLastRequest = 100;
	let requestAmount = 0;
	let error = false;

	const items: {[id: number | string] : any} = {};
	while(itemsInLastRequest === 100 && requestAmount < 1000 && !error){
		await directus.request(readItems(collection, {offset: requestAmount * 100, fields: params.fields ? ['id', ...params.fields] : ['*'], filter: params.filter ?? {}, deep: params.deep ?? {}}))
			.catch((err) => {
				error = true;
				catchDirectusError('read '+collection, err);
			})
			.then((request) => {
				requestAmount++;
				if(request){
					itemsInLastRequest = request.length;
					request.forEach(item => {
						//@ts-expect-error every type has id but still gives an error
						items[item.id] = item;
					});
				} else error = true;
			});
	}

	return items;
};

/** retrieves single item from given collection
	 * @param collection name of the collection to read
	 * @param id id of item
*/
export const retrieveItemFromCollection = async (collection: keyof Schema, id: number | string, params: {fields?: string[], deep?: any}) => {

	let item: any;
	await directus.request(readItem(collection, id,{fields: params.fields ? ['id', ...params.fields] : ['*'], deep: params.deep ?? {}}))
		.catch((err) => {
			catchDirectusError('read '+collection, err);
		})
		.then((request) => {
			item = request;
		});
	

	return item;
};


export const retrieveDirectusUsers = async () => {
	
	const users: {[id: string] : any} = {};

	await directus.request(readUsers())	.then((request) => {
		request.forEach(user => {
			users[user.id] = user;
		});
	});
	return users;
};

export const retrieveDirectusFiles = async (params: {filter?: any, fields?: any}) => {
	
	const files: {[id: string] : any} = {};

	await directus.request(readFiles({fields: params.fields ? ['id', ...params.fields] : ['*'], filter: params.filter ?? {}}))	.then((request) => {
		request.forEach(file => {
			files[file.id] = file;
		});
	});
	return files;
};