
const SceneswapIcon = () => {
	return (
		<svg width="40" height="41" viewBox="-7 -7 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M20.0292 9.85548L22.0292 9.85548C22.0292 9.85548 22.3175 6.94194 20.5918 5.04912C19.5282 3.88252 18.0425 3.32061 17.059 3.06116C16.5156 2.9178 16.0292 3.34512 16.0292 3.90711C16.0292 4.43051 16.4578 4.86363 16.965 4.99288C17.6175 5.15916 18.4867 5.52665 19.151 6.33885C20.3514 7.80659 20.0382 9.80043 20.0294 9.85436L20.0292 9.85548Z" fill="black"/>
			<path d="M23.323 8.56173L22.0305 9.85548L21.0305 9.85548L20.0305 9.85548L20.0292 9.85548L20.0294 9.85436L18.738 8.56173C18.5504 8.37409 18.2959 8.26868 18.0305 8.26868C17.7652 8.26868 17.5107 8.37409 17.323 8.56173C17.1354 8.74937 17.03 9.00387 17.03 9.26923C17.03 9.53459 17.1354 9.78909 17.323 9.97673L20.323 12.9767C20.4159 13.0697 20.5262 13.1435 20.6476 13.1938C20.769 13.2441 20.8991 13.27 21.0305 13.27C21.162 13.27 21.2921 13.2441 21.4135 13.1938C21.5349 13.1435 21.6452 13.0697 21.738 12.9767L24.7381 9.97673C24.831 9.88382 24.9047 9.77352 24.9549 9.65213C25.0052 9.53073 25.0311 9.40063 25.0311 9.26923C25.0311 9.13784 25.0052 9.00773 24.9549 8.88633C24.9047 8.76494 24.831 8.65464 24.7381 8.56173C24.6451 8.46882 24.5348 8.39512 24.4134 8.34484C24.2921 8.29455 24.1619 8.26868 24.0305 8.26868C23.8992 8.26868 23.769 8.29455 23.6477 8.34484C23.5263 8.39512 23.416 8.46882 23.323 8.56173Z" fill="black"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M18.2607 15.1602L23.5297 15.1602C25.4627 15.1602 27.0297 16.7272 27.0297 18.6602L27.0297 23.9292C27.0297 25.8622 25.4627 27.4292 23.5297 27.4292L18.2607 27.4292C16.3277 27.4292 14.7607 25.8622 14.7607 23.9292L14.7607 18.6602C14.7607 16.7272 16.3277 15.1602 18.2607 15.1602ZM18.2607 17.1602C17.4322 17.1602 16.7607 17.8317 16.7607 18.6602L16.7607 23.9292C16.7607 24.7576 17.4322 25.4292 18.2607 25.4292L23.5297 25.4292C24.3581 25.4292 25.0297 24.7576 25.0297 23.9292L25.0297 18.6602C25.0297 17.8317 24.3581 17.1602 23.5297 17.1602L18.2607 17.1602Z" fill="black"/>
			<path d="M8.45987 18.7358L6.45987 18.7358C6.45987 18.7358 6.17166 21.6494 7.89735 23.5422C8.96095 24.7088 10.4466 25.2707 11.4301 25.5301C11.9735 25.6735 12.4599 25.2462 12.4599 24.6842C12.4599 24.1608 12.0313 23.7277 11.5241 23.5984C10.8716 23.4322 10.0024 23.0647 9.33811 22.2525C8.13772 20.7847 8.45095 18.7909 8.45969 18.7369L8.45987 18.7358Z" fill="black"/>
			<path d="M5.16607 20.0296L6.45857 18.7358L7.45857 18.7358L8.45857 18.7358L8.45987 18.7358L8.45969 18.7369L9.75107 20.0296C9.93871 20.2172 10.1932 20.3226 10.4586 20.3226C10.7239 20.3226 10.9784 20.2172 11.1661 20.0296C11.3537 19.8419 11.4591 19.5874 11.4591 19.3221C11.4591 19.0567 11.3537 18.8022 11.1661 18.6146L8.16607 15.6146C8.0732 15.5216 7.96291 15.4478 7.84151 15.3975C7.72011 15.3472 7.58998 15.3213 7.45857 15.3213C7.32715 15.3213 7.19703 15.3472 7.07563 15.3975C6.95423 15.4478 6.84394 15.5216 6.75107 15.6146L3.75106 18.6146C3.65815 18.7075 3.58445 18.8178 3.53417 18.9392C3.48389 19.0606 3.45801 19.1907 3.45801 19.3221C3.45801 19.4535 3.48389 19.5836 3.53417 19.705C3.58445 19.8264 3.65815 19.9367 3.75106 20.0296C3.84397 20.1225 3.95428 20.1962 4.07567 20.2465C4.19706 20.2968 4.32717 20.3226 4.45857 20.3226C4.58996 20.3226 4.72007 20.2968 4.84146 20.2465C4.96286 20.1962 5.07316 20.1225 5.16607 20.0296Z" fill="black"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M4.4209 0.429687L10.6064 0.429687C12.5394 0.429687 14.1064 1.99669 14.1064 3.92969L14.1064 10.1152C14.1064 12.0482 12.5394 13.6152 10.6064 13.6152L4.4209 13.6152C2.4879 13.6152 0.920898 12.0482 0.920898 10.1152L0.920898 3.92969C0.920898 1.99669 2.4879 0.429687 4.4209 0.429687ZM4.4209 2.42969C3.59247 2.42969 2.9209 3.10126 2.9209 3.92969L2.9209 10.1152C2.9209 10.9437 3.59247 11.6152 4.4209 11.6152L10.6064 11.6152C11.4349 11.6152 12.1064 10.9437 12.1064 10.1152L12.1064 3.92969C12.1064 3.10126 11.4349 2.42969 10.6064 2.42969L4.4209 2.42969Z" fill="black"/>
		</svg>

	);
};

export default SceneswapIcon;