import { FormEvent, useEffect, useState } from 'react';
import { directus } from '../tools/directus';
import { useNavigate } from 'react-router-dom';
import { LoginOptions } from '@directus/sdk';
import styled from 'styled-components';
import { Button } from '../Interface/Interactables/Buttons';
import { Logo } from '../Interface/Logo';
import { checkDirectusAuthentication } from '../tools/authentication';
import { LoginInput } from '../Interface/Interactables/Input';

const errorMessages: { [key: string]: string } = {
	'Invalid user OTP.': 'Invalid one time password'
};

const Login = () => {

	const navigate = useNavigate();

	const [error, setError] = useState<string>();
	const [OTP, setOTP] = useState<boolean>(false);

	const onSubmit = async (e: FormEvent<HTMLFormElement>) => {

		const loginOptions: LoginOptions = { mode: 'json' };
		// @ts-expect-error fields in FormEvent{
		if (e.target.otp && e.target.otp.value) loginOptions.otp = e.target.otp.value;
		// @ts-expect-error fields in FormEvent{
		await directus.login(e.target.username.value, e.target.password.value, loginOptions).then(async (e) => {
			navigate('/');
		}).catch((e) => {
			if (e.errors[0].message === 'Invalid user OTP.' && !OTP) {
				setOTP(true);
			} else {
				const rawMessage: string = e.errors[0].message;
				rawMessage && (errorMessages[rawMessage] ? setError(errorMessages[rawMessage]) : setError(rawMessage));
			}
		});

	};

	// checks the auth and redirects to the editor

	const checkAuth = async () => {
		await checkDirectusAuthentication().then(async () => {
			navigate('/');
		}).catch(() => {
			return;
		});
	};

	useEffect(() => {
		checkAuth();
	}, []);

	return (
		<Container>
			<Content>
				<Title>
					<Logo />
					<span>Learning Lab</span>
					<span>Editor</span>
				</Title>
				<form onSubmit={(e) => { e.preventDefault(); onSubmit(e); }}>

					<>
						<LoginInput type="email" name='username' placeholder='Email' required style={{ display: OTP ? 'none' : 'block' }}></LoginInput>
						<LoginInput type="password" name='password' placeholder='Password' required style={{ display: OTP ? 'none' : 'block' }}></LoginInput>
					</>

					{/* OTP sections */}
					{OTP &&
						<>
							<LoginInput type="otp" name="otp" placeholder='One-time password'></LoginInput>
						</>
					}

					{/* submit */}
					<Button type='submit'>{OTP ? 'Verify code' : 'Log in'}</Button>


					{/* Errors */}
					<div style={{ color: 'red' }}>{error && error}</div>

				</form>
			</Content>
		</Container>

	);
};

// sc

const Container = styled.div`
	overflow-y: auto;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 400px;
	margin: auto;

	gap: ${p => p.theme.spacing.hug}px;

	& > form {
		display: flex;
		flex-direction: column;
		gap: ${p => p.theme.spacing.gap}px;

		transition: all 1s ease-in-out;
	}
`;

const Title = styled.div`

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 2em;
	line-height: 1;
	height: 40dvh;
	min-height: 300px;


	& >:nth-child(1) {
		color: ${p => p.theme.colors.neutralDarkest}
	}
	& >:nth-child(2) {
		font-size: 1.5;
		color: ${p => p.theme.colors.primary}
	}
`;

export default Login;