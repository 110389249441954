import styled from 'styled-components';
import { Children, HTMLAttributes } from 'react';
import thinking from '../../../assets/images/ellie_thinking_lowres.png';

const EllieDescription = (props: EllieSaysProps) => {

	return ( 
		<Container {...props}>
			<EllieAvatar $avatar={thinking}/>
			<Message>
				{Children.toArray(props.children).map((child, index) => {
					return(	
						<div key={`child-${index}`}>	
							{child}
						</div>
					);
				})}
			</Message>
		</Container>
	);
};

// sc

const Container = styled.div`
	display: flex;
	width: 100%;
	padding: ${p => p.theme.styles.padding*2}px;
`;

const EllieAvatar = styled.div<{$avatar: string}>`

	background-image: url(${p => p.$avatar});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	scale: 1 1;

	aspect-ratio: 1/1;
	height: 150px;
`;

const Message = styled.div`

	background-color: ${p => p.theme.colors.neutral};
	color: ${p => p.theme.colors.neutralLightest};

	padding: 20px;
	border-radius: 30px;

	width: 100%;
	position: relative;

	font-style: italic;

	height: fit-content;
	margin: auto;


	&:before{
		content:'';
		position:absolute;

		border: 10px solid transparent;

		right:100%;bottom:50%;
		translate: 5% 50%;
		border-right-color: ${p => p.theme.colors.neutral};

		height:0;width:0;
		border-width: 10px;
	}

`;

// types

interface EllieSaysProps extends HTMLAttributes<HTMLDivElement>{

}
 
export default EllieDescription;