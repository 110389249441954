import { useAtom, useAtomValue } from 'jotai/react';
import { EndingNode, MessageNode, NodeType, StickynoteNode, flowDataAtom, nodesAtom, SceneswapNode } from '../atoms/flow';
import { selectedScenarioIdAtom, connectingFromHandle } from '../atoms/editor';
import { createEndingInDirectus, createMessageInDirectus, createStickynoteInDirectus, createSceneswapInDirectus } from '../tools/directusActions/create';
import { Node, XYPosition, useReactFlow } from 'reactflow';
import { useAddEdge } from './edgeHooks';
import { useUpdateNodeData } from './updateNodeHooks';
import { useUpdateNodeAnswer } from './answerHooks';
import { directus } from '../tools/directus';
import { readMe } from '@directus/sdk';

export const useAddNode = () => {
	const addEndingNode = useAddEdningNode();
	const addMessageNode = useAddMessageNode();
	const addStickynoteNode = useAddStickynoteNode();
	const addSceneswapNode = useAddSceneswapNode();

	const {screenToFlowPosition} = useReactFlow();

	return async (type: NodeType | 'context', position: XYPosition) => {
		//shared values
		const shared = {
			id: '', //will be set to [type]-[directus id] after creation
			position: screenToFlowPosition(position),
			selected: false,
			data: {
				directus_id: null,
			},
		};

		switch (type) {
		case 'context':	case 'message':
			await addMessageNode(shared, type === 'context' ? 1 : 0);
			break;
		case 'ending':
			await addEndingNode(shared);
			break;
		case 'stickynote':
			await addStickynoteNode(shared);
			break;
		case 'sceneswap':
			await addSceneswapNode(shared);
			break;
		}
	};
};

const useAddMessageNode = () => {
	const [scenarioId] = useAtom(selectedScenarioIdAtom);
	const [, setNodes] = useAtom(nodesAtom);
	const addEdge = useAddEdge();
	const [handle, setHandle] = useAtom(connectingFromHandle);
	const updatedNodeData = useUpdateNodeData();
	const updatedAnswerData = useUpdateNodeAnswer();


	return async (defaults: Node, type: 1 | 0) => {
		if(!scenarioId) return;

		const newNode: MessageNode = {
			...defaults,
			position: {x: handle && handle.handleId.includes('target') ? defaults.position.x-350 : defaults.position.x, y: defaults.position.y-47},
			type: 'message',
			data: {
				...defaults.data,
				message_type: type,
				answers: {},
				translations: {},
				follow_up_message: handle && handle.handleId.includes('target-message') ? parseInt(handle.nodeId.replace('message', '')) : null,
				speaking_character: null,
				events: [],
				ending: handle && handle.handleId.includes('target-ending') ? parseInt(handle.nodeId.replace('ending', '')) : null,
				reset: false
			}
		};
		
		await createMessageInDirectus(newNode, scenarioId).then((request: any) => {
			newNode.id = newNode.type + request.id.toString();
			newNode.data.directus_id = request.id;
			setNodes((nodes) => [...nodes, newNode]);

			if(!handle) return;

			if(handle.handleId.includes('answer')){//dragging from answer
				addEdge({
					id: `e-${handle.nodeId}-answer${handle.handleId.split('answer')[1]}-message${request.id}`,
					source: handle.nodeId,
					sourceHandle: handle.handleId,
					target: `message${request.id}`,
					targetHandle: `target-message${request.id}`,
				});
				const keys = handle.handleId.split('-');
				updatedAnswerData(keys[1], keys[2], {field: 'follow_up_message', newValue: request.id});

			} else if(handle.handleId.includes('source')){//dragging from source
				addEdge({
					id: `e-${handle.nodeId}-message${request.id}`,
					source: handle.nodeId,
					target: `message${request.id}`
				});

				if(handle.nodeId.includes('introduction'))
					updatedNodeData(handle.nodeId, {field: 'starting_message', newValue: request.id});	
				if(handle.nodeId.includes('sceneswap'))
					updatedNodeData(handle.nodeId, {field: 'target_message_id', newValue: request.id});
				else	
					updatedNodeData(handle.nodeId, {field: 'follow_up_message', newValue: request.id.toString()});

			} else if(handle.handleId.includes('target')){//dragging from target
				addEdge({
					id: `e-message${request.id}-${handle.nodeId}`,
					source: `message${request.id}`,
					target: handle.nodeId,
				});
			} 
			
			setHandle(undefined);
		});
	};
};

const useAddEdningNode = () => {
	const [scenarioId] = useAtom(selectedScenarioIdAtom);
	const [, setNodes] = useAtom(nodesAtom);
	const [flowsData] = useAtom(flowDataAtom);

	return async (defaults: Node) => {
		if(!flowsData || !scenarioId) return;

		const newNode : EndingNode ={
			...defaults,
			type: 'ending',
			data: {
				...defaults.data,
				translations: {},
				achieved: false,
			}
		}; 
		await createEndingInDirectus(newNode, scenarioId).then((request: any) => {
			newNode.id = newNode.type + request.id.toString();
			newNode.data.directus_id = request.id;
			setNodes((nodes) => [...nodes, newNode]);
		});
	};
};

const useAddStickynoteNode = () => {
	const [scenarioId] = useAtom(selectedScenarioIdAtom);
	const [, setNodes] = useAtom(nodesAtom);
	const [flowsData] = useAtom(flowDataAtom);

	return async (defaults: Node) => {
		if(!flowsData || !scenarioId) return;
		const me = await directus.request(readMe({fields: ['id']}));

		const newNode : StickynoteNode ={
			...defaults,
			type: 'stickynote',
			data: {
				...defaults.data,
				color: '#FFFF97',
				text: '',
				createdBy: me.id
			}
		}; 

		await createStickynoteInDirectus(newNode, scenarioId).then((request: any) => {
			newNode.id = newNode.type + request.id.toString();
			newNode.data.directus_id = request.id;
			setNodes((nodes) => [...nodes, newNode]);
		});
	};
};

const useAddSceneswapNode = () => {
	const [, setNodes] = useAtom(nodesAtom);
	const [flowsData] = useAtom(flowDataAtom);
	const scenarioId = useAtomValue(selectedScenarioIdAtom);

	return async (defaults: Node) => {
		if(!flowsData || !scenarioId) return;

		const newNode : SceneswapNode = {
			...defaults,
			position: {x: defaults.position.x-50,y: defaults.position.y-50},
			type: 'sceneswap',
			data: {
				...defaults.data,
				source_message_ids: [],
				target_message_id: null,
				scene_id: ''
			}
		}; 
		await createSceneswapInDirectus(newNode, scenarioId).then((request: any) => {
			newNode.id = newNode.type + request.id.toString();
			newNode.data.directus_id = request.id;
			setNodes((nodes) => [...nodes, newNode]);
		});

	};
};