import { NodeProps, Position } from 'reactflow';
import FollowUpMessageHandle from '../../handles/FollowUpMessageHandle';
import styled, { css } from 'styled-components';
import { HTMLAttributes, useEffect, useMemo } from 'react';
import SceneswapIcon from '../../../assets/icons/NodeSceneswapIcon';
import { useGetNodeWithId, useUpdateNodeData } from '../../../hooks/updateNodeHooks';
import { MessageNode } from '../../../atoms/flow';

export type SceneswapNodeData = {
	directus_id: number | null 
	source_message_ids: number[] //directus message ids containing the scene swap
	target_message_id: number | null //directus message id of the message to set after swap
	scene_id: string
}

const SceneswapNode = (props: NodeProps<SceneswapNodeData>) => {

	const sourceHandleId = useMemo(() => props.id ? `source-${props.id}-` : undefined, [props.id]);
	const targetHandleId = useMemo(() => props.id ? `target-${props.id}` : undefined, [props.id]);

	const updateNodeData = useUpdateNodeData();
	const getNodeWithId = useGetNodeWithId<MessageNode>();

	useEffect(() => {
		props.data.source_message_ids.map((id) => {
			const node = getNodeWithId(`message${id}`);	if(!node) return; 
			if(props.data.target_message_id){
				if(!node.data.events.find((e) => e.type === 'set-message'))
					node.data.events = [...node.data.events, {type : 'set-message',  message_id: props.data.target_message_id.toString(), direct: false, fifo: false}];
			} else { 
				node.data.events = node.data.events.filter((e) => e.type != 'set-message');
			}
			updateNodeData(`message${id}`,{field: 'events',newValue: node.data.events});
		});
	}, [props.data.target_message_id]);

	return (
		<Node {...props}>
			{targetHandleId && <FollowUpMessageHandle type="target" position={Position.Left} id={targetHandleId}/>}
			<SceneswapIcon/>
			{sourceHandleId && <FollowUpMessageHandle type="source" position={Position.Right} id={sourceHandleId} isConnectable={props.data.target_message_id === null}
			/>}
		</Node>
	);
};

const Node = styled.div<HTMLAttributes<HTMLDivElement> & NodeProps>`

	background-color: ${p => p.theme.colors.neutralLightest};
	border-radius: 100%;

	width: 100px;
	aspect-ratio: 1/1;

	display: flex;
	justify-content: center;
	align-items: center;

	${p => p.selected && css`
		outline: solid ${p => p.theme.styles.borderWidth}px ${p => p.theme.colors.primary};
	`}

`;
 
export default SceneswapNode;