import { NodeProps, Position } from 'reactflow';
import FollowUpMessageHandle from '../../handles/FollowUpMessageHandle';
import styled, { css } from 'styled-components';
import { useAtom, useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../atoms/atoms';
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import EllieSays from './EllieSays';
import { useUpdateNodeData, useUpdateNodeValues } from '../../../hooks/updateNodeHooks';
import { textareaAutoGrow } from '../../../utilities/helpers';
import { dragCanvasAllowed } from '../../../atoms/editor';
import { Input } from '../../../Interface/Interactables/Input';

export type IntroductionNodeData = {
	directus_id: number
	translations: IntroductionTranslations,
	starting_message: number | null
}

export type IntroductionTranslations= {[lng: string] : {
	directus_id: number | null,
	name: string
	description: string
	ellie_description: string
}}

const IntroductionNode = (props: NodeProps<IntroductionNodeData>) => {

	const language = useAtomValue(activeLanguageAtom);
	const sourcehandleId = useMemo(() => props.id ?`source-${props.id}` : undefined, [props.id]);
	const [, setDragAllowed] = useAtom(dragCanvasAllowed);
	const updateNodeData = useUpdateNodeData();
	const updateNodeValues = useUpdateNodeValues();

	const [name, setName] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [ellieDescription, setEllieDescription] = useState<string>('');

	const translation =  useMemo(() => {
		if(!props.data.translations || !language || !props.data.translations[language]) return;
		return  props.data.translations[language];
	},[language, props.data.translations]);

	useEffect(() => {
		setName(translation ? translation.name : '');
		setDescription(translation ? translation.description : '');
		setEllieDescription(translation ? translation.ellie_description : '');
	}, [translation]);

	const onBlur = () => {
		setDragAllowed(true);
		updateNodeValues(props.id, {field: 'draggable', newValue: true});
		const updatedTranslations = {...props.data.translations};

		updatedTranslations[language] = {
			directus_id: updatedTranslations[language] ? updatedTranslations[language].directus_id : null,
			name: name,	description: description, ellie_description: ellieDescription
		};
		
		updateNodeData(props.id, {field: 'translations', newValue: updatedTranslations});
	};

	const disableDragging = () => {
		setDragAllowed(false);
		updateNodeValues(props.id, {field: 'draggable', newValue: false});
	};
	const descriptionRef = useRef<HTMLTextAreaElement>(null);
	const elliesaysRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if(!descriptionRef.current) return;
		descriptionRef.current.style.height = descriptionRef.current.scrollHeight+'px';
	}, [description]);

	useEffect(() => {
		if(!elliesaysRef.current) return;
		elliesaysRef.current.style.height = elliesaysRef.current.scrollHeight+'px';
	}, [ellieDescription]);


	return (
		<Node {...props} >
			<HeaderBar>
				<StyledInput
					type='text'
					onBlur={onBlur}
					onKeyDown={(e) => {
						if(e.code === 'Enter'){ e.preventDefault(); e.currentTarget.blur();}
					}}
					onFocus={disableDragging}
					value={name}
					onInput={(e) => {(textareaAutoGrow(e));}}
					onChange={(e) => setName(e.target.value)}
					placeholder='Type the name here ...'
				/>
			</HeaderBar>
			<Description>
				<StyledTextarea
					ref={descriptionRef}
					onBlur={onBlur}
					onKeyDown={(e) => {
						if(e.code === 'Enter' && !e.shiftKey){ e.preventDefault(); e.currentTarget.blur();}
					}}
					onFocus={disableDragging}
					placeholder='Type the description here ...'
					value={description}
					onInput={(e) => {(textareaAutoGrow(e));}}
					onChange={(e) => {setDescription(e.target.value);}}
					type='description'
				/>		
			</Description>
			<EllieSays>
				<StyledTextarea
					ref={elliesaysRef}
					onBlur={onBlur}
					onKeyDown={(e) => {
						if(e.code === 'Enter' && !e.shiftKey){ e.preventDefault(); e.currentTarget.blur();}
					}}
					onFocus={disableDragging}
					placeholder='Type the context here ...'
					value={ellieDescription}
					onInput={(e) => {(textareaAutoGrow(e));}}
					onChange={(e) => setEllieDescription(e.target.value)}
					type='elliesays'
				/>		
			</EllieSays>
			<FollowUpMessageHandle isConnectable={props.data.starting_message === null} id={sourcehandleId} type="source" position={Position.Right}/>
		</Node>
	);
};

const Node = styled.div<HTMLAttributes<HTMLDivElement> & NodeProps>`
	*:focus {
		outline: none;
	}
	background-color: ${p => p.theme.colors.neutralLightest};
	border-radius: ${p =>p.theme.styles.borderRadius}px;

	width: 500px;
	margin-inline: auto;

	display: block;

	${p => p.selected && css`
		outline: solid ${p => p.theme.styles.borderWidth}px ${p => p.theme.colors.primary};
	`}

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const HeaderBar = styled.div`

	display: flex;
	justify-content: center;
	align-items: center;	
	background-color: ${p => p.theme.colors.tinqwise};

	border-radius: ${p =>p.theme.styles.borderRadius}px ${p =>p.theme.styles.borderRadius}px 0px 0px;
	padding: ${p => p.theme.styles.padding*2}px;
`;

const StyledInput = styled(Input)`
	font-weight: bold;
	font-size: 24px;
	text-align: center;
	color: white;
	background: none;
	&::placeholder {
		color: white;
		opacity: 0.7;
	}
	
`;

const StyledTextarea = styled.textarea<{type: 'elliesays' | 'description'}>`
	width: 100%;
	outline: none;
	border: none;
	font-family: poppins;
	resize: none;
	color: white;
	background: none;
	overflow: hidden;
	resize: none;

	${p => p.type === 'description' && css`
		color: black;
	`}

	${p => p.type === 'elliesays' && css`
		&::placeholder {
			color: white;
			opacity: 0.7;
		}
		color: white;
		font-style: italic;
	`}

`;

const Description = styled.div`
	padding: ${p => p.theme.styles.padding*2}px;
`;
 
export default IntroductionNode;