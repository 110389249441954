import { useMemo } from 'react';
import { MessageNode } from '../../../../atoms/flow';
import { IconButton } from '../../../Interactables/Buttons';
import { MdAdd } from 'react-icons/md';
import { Answer } from './Answer';
import { useAddNewAnswer } from '../../../../hooks/answerHooks';
import { Header } from '../components/Header';

const Answers = (node: MessageNode) => {	
	const addAnswer = useAddNewAnswer();

	const answers = useMemo(() => {
		if(!node || !node.data.answers) return;
		return node.data.answers;
	},[node]);

	const onAddAnswer = () => {
		addAnswer(node.id);
	};

	//#endregion

	return ( 	 
		<>
			<Header>
				<h3>
					Answers 
				</h3>
				<IconButton 
					size='small' 
					onClick={onAddAnswer}
					disabled={!answers ? true : answers && Object.keys(answers).length > 3}
				><MdAdd/></IconButton>
			</Header>
			{answers && Object.entries(answers).map(([id,answer], index) => 
				<Answer key={`node-editor-message-answer-field-${id}`} index={index} answerId={id} answerData={answer} selectedNodeId={node.id}/>
			)}
		</>
	);
};

export default Answers;