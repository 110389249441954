import { MdAdd, MdDelete } from 'react-icons/md';
import styled from 'styled-components';
import { editorDataAtom } from '../../../atoms/editor';
import { useUpdateNodeData } from '../../../hooks/updateNodeHooks';
import { AnimationAction, GameEvent, MessageNode, MoodAction } from '../../../atoms/flow';
import { useAtomValue } from 'jotai';
import { Header } from './components/Header';
import { IconButton } from '../../Interactables/Buttons';
import { Label } from '../../misc/Label';
import CharactersDropdown from './components/CharactersDropdown';

const AnimationField = (node: MessageNode) => {
	const updateNodeDataWithId = useUpdateNodeData();

	const editorData = useAtomValue(editorDataAtom);

	const onAddAnimationTrigger = () => {
		if(!node || ! editorData) return;
		const newEvent: GameEvent = {
			direct: true,
			fifo: false,
			type: 'set-character-animation',
			object_id: node.data.speaking_character ?? Object.values(editorData.characters)[0].id,
			animation: 'none'
		};
		const newEvents = [...node.data.events, newEvent];

		updateNodeDataWithId(node.id, {field: 'events', newValue: newEvents});
	};

	const onChangeAnimation = (value: string, index: number) => {
		if(!node) return;
		const newEvents: GameEvent[] = node.data.events;

		const event = newEvents[index] as MoodAction | AnimationAction;

		const shared = {
			direct: true,
			fifo: false,
			object_id: event.object_id
		};

		if(value.includes('mood:')){
			newEvents[index] = {
				...shared,
				type: 'set-character-mood',
				mood: value.replace('mood:',''),
			};
			
		} else {
			newEvents[index] = {
				...shared,
				type: 'set-character-animation',
				animation: value,
			};
		}

		updateNodeDataWithId(node.id, {field: 'events', newValue: newEvents});
	};

	const onChangeCharacter = (value: number, index: number) => {
		if(!node) return;
		
		const newEvents: GameEvent[] = node.data.events.map((event, i) => {
			if(index === i)
				return {...event, object_id: value};
			else
				return event;
		});

		updateNodeDataWithId(node.id, {field: 'events', newValue: newEvents});
	};

	const onDeleteAnimationTrigger = (index: number) => {
		if(!node) return;
		const newEvents =	node.data.events.filter((_e, i) => i != index);
		updateNodeDataWithId(node.id, {field: 'events', newValue: newEvents});
	};

	return (   
		<>
			{ editorData && 
				<>
					<Header>
						<h3>Animation Triggers</h3>
						<IconButton
							size='small' 
							onClick={onAddAnimationTrigger}
						><MdAdd/></IconButton>
					</Header>
					{node && node.data.events.map((event, index) => {
						if(event.type === 'set-character-animation' || event.type === 'set-character-mood')
							return (
								<Container key={`event-${event.type}-${index}`}>
									<Label>
                                    apply animation 
										<select 
											value={event.type === 'set-character-animation' ? event.animation ?? '' : event.mood ? 'mood:'+event.mood : ''} 
											onChange={(e) => onChangeAnimation(e.target.value, index)} 
											style={{maxWidth: '50%'}}
										>
											<option value={'null'}>none</option>
											{editorData.animations.map((animationOption) => 
												<option key={`anim-${animationOption}`} value={animationOption}>{animationOption}</option>
											)}
											{editorData.moods.map((moodOption) => 
												<option key={`mood-${moodOption}`} value={'mood:'+moodOption}>mood: {moodOption}</option>
											)}
										</select>
									</Label>
									<Label>
                                        to
										<CharactersDropdown 
											value={event.object_id ?? 0} 
											onSelectChange={(e) => onChangeCharacter(parseInt(e.target.value), index)}
										/>
										<IconButton size='small' onClick={() => onDeleteAnimationTrigger(index)}><MdDelete/></IconButton>
									</Label>
								</Container>
							);})
					}
				</>
			}
		</>
	);
};
const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.styles.padding}px;

	padding: ${p => p.theme.styles.padding}px;

	border: 2px solid ${p => p.theme.colors.neutralLightest};
	border-radius: ${p => p.theme.styles.borderRadius}px;
`;

export default AnimationField;