import { useAtomValue } from 'jotai';
import styled from 'styled-components';
import { flowDataAtom } from '../../atoms/flow';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { updateScenarioInDirectus } from '../../tools/directusActions/update';
import { Input } from '../Interactables/Input';

const ScenarioName = ({toggleOptions}: {toggleOptions: () => void}) => {

	const flowsData = useAtomValue(flowDataAtom);
	const [name, setName] = useState<string>('');

	useEffect(() => {
		if(flowsData)
			setName(flowsData.scenario.name);
	}, [flowsData]);

	const onBlur = () => {
		if(flowsData)
			updateScenarioInDirectus(flowsData.scenario.id,{name: name});
	};

	return (
		<Container>
			<div>
				<Input type='text' 
					onBlur={onBlur}
					onKeyDown={(e) => {
						if(e.code === 'Enter'){ e.preventDefault(); e.currentTarget.blur();}
					}}
					onChange={(e) => setName(e.target.value)} 
					value={name} 
					style={{width: `${name.length === 0 ? 5 : name.length}ch`, background: 'none'}}
				/>
				<MdOutlineKeyboardArrowDown style={{cursor: 'pointer'}} onClick={() => toggleOptions()} />
			</div>
		</Container>
	);
};

// sc

const Container = styled.div`
	margin-inline: auto;
	& > div {
		align-items: center;
		display: flex;	
	}
`;

 
export default ScenarioName;