import styled from 'styled-components';
import Focused from './FocusedWindows/Focused';
import BottomRow from './BottomRow/BottomRow';
import HeaderBar from './HeaderBar/HeaderBar';
import Menu from './Menu/Menu';

const Interface = () => {

	return (  
		<Container>
			<HeaderBar/>
			<Menu/>
			<BottomRow/>
			<Focused/>
		</Container>
	);
};

const Container = styled.div`

	position: absolute;
	inset: 0;
	pointer-events: none;

	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;




export default Interface;