import { deleteItem } from '@directus/sdk';
import { Schema, catchDirectusError, directus } from '../directus';
import { CustomNode, EndingNode, MessageNode, NodeType, collections } from '../../atoms/flow';
import { Answer } from '../../Editor/nodes/MessageNode/MessageNode';

export const deleteNodeInDirectus = (node: CustomNode) => {
	const type = node.type as NodeType;
	node.data.directus_id && directus.request(deleteItem(collections[type],node.data.directus_id)).catch((err: any) => {
		catchDirectusError(`${node.type} delete ${node.data.directus_id}`, err);
	});
	if(node.type === 'message') Object.values(node.data.answers).map((answer) => deleteAnswerInDirectus(answer));
	if(node.type === 'message' || node.type === 'ending') deleteNodeTranslationsInDirectus(node);
};

const deleteNodeTranslationsInDirectus = (node: EndingNode | MessageNode) => {
	const collection: keyof Schema = node.type === 'ending' ? 'product_2_scenarios_endings_translations' : 'product_2_dialogues_messages_translations';
	Object.values(node.data.translations).map((translation) => {
		directus.request(deleteItem(collection,translation.directus_id)).catch((err: any) => {
			catchDirectusError(`${node.type} translation delete ${node.data.directus_id}`, err);
		});
	});
};

export const deleteAnswerInDirectus = (answerData: Answer) => {
	deleteAnswerTranslationsInDirectus(answerData);
	answerData.directus_id && directus.request(deleteItem('product_2_dialogues_messages_answers',answerData.directus_id)).catch((err: any) => {
		catchDirectusError(`answer delete ${answerData.directus_id}`, err);
	});
};

const deleteAnswerTranslationsInDirectus = (answerData: Answer) => {
	Object.values(answerData.translations).map((translation) => {
		translation.directus_id && directus.request(deleteItem('product_2_dialogues_messages_answers_translations',translation.directus_id)).catch((err: any) => {
			catchDirectusError(`answer translation delete ${answerData.directus_id}`, err);
		});
	});
};