import { css } from 'styled-components';

export const Pill = css`
	background-color: white;
	border-radius: 50em;
	padding: 1em 1.75em;

	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	font-weight: 400;
`;