import { MdDelete } from 'react-icons/md';
import { IconButton } from '../../../Interactables/Buttons';
import styled from 'styled-components';
import { Answer as AnswerProps } from '../../../../Editor/nodes/MessageNode/MessageNode';
import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../../atoms/atoms';
import { NodeEditorProps } from '../../EditWindow';
import { TextField } from '../components/TextField';
import CorrectnessField from './CorrectnessField';
import { useDeleteNodeAnswer, useUpdateNodeAnswer } from '../../../../hooks/answerHooks';

export const Answer = ({answerData, answerId, index, selectedNodeId}: AnswerFieldProps) => {
	
	const language = useAtomValue(activeLanguageAtom);

	const updateNodeAnswer = useUpdateNodeAnswer();
	const deleteNodeAnswser = useDeleteNodeAnswer();

	const [text, setText] = useState<string>('');
	const [feedback, setFeedback] = useState<string>('');
	const [feedbackTitle, setFeedbackTitle] = useState<string>('');
	
	const translation =  useMemo(() => {
		if(!answerData.translations || !language || !answerData.translations[language]) return;
		return  answerData.translations[language];
	},[language, answerData.translations]);

	useEffect(() => {
		setText(translation ? translation.text : '');
		setFeedback(translation ? translation.feedback_body : '');
		setFeedbackTitle(translation ? translation.feedback_title : '');
	}, [translation]);

	const onAnswerFieldBlur = () => {
		const updatedTranslations = {...answerData.translations};
		if(updatedTranslations[language])
			updatedTranslations[language] = {directus_id: updatedTranslations[language].directus_id,text: text, feedback_title: feedbackTitle, feedback_body: feedback};
		else
			updatedTranslations[language] = {directus_id: null,text: text, feedback_title: feedbackTitle, feedback_body: feedback};
		updateNodeAnswer(selectedNodeId, answerId ,{field: 'translations', newValue: updatedTranslations});
	};

	const onTextChange : ChangeEventHandler<HTMLInputElement> = (e) => {
		setText(e.target.value);
		return;
	};

	const onCorrectnessChange = (correctness: AnswerProps['correctness']) => {
		updateNodeAnswer(selectedNodeId, answerId, {field:'correctness', newValue: correctness === answerData.correctness ? null : correctness});
	};

	return (
		<Container>
			<div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
				<h3 style={{ margin: '0'}}>Answer {index+1}</h3>
				<IconButton size='small' style={{marginInlineStart: 'auto'}} onClick={() => deleteNodeAnswser(selectedNodeId,answerId,answerData)}><MdDelete/></IconButton>
			</div>
			<CorrectnessField correctness={answerData.correctness} onChangeCorrectness={onCorrectnessChange}/>
			<TextField>
				Text
				<input 
					onChange={onTextChange}
					onBlur={onAnswerFieldBlur}
					style={{resize: 'vertical'}} 
					placeholder='type the text for an answer here!'
					value={text}
				/>
			</TextField>
			{answerData.correctness != null &&
			<>
				<TextField>
				Feedback Title
					<input
						value={feedbackTitle}
						onChange={(e) => setFeedbackTitle(e.target.value)}
						onBlur={onAnswerFieldBlur}
						placeholder='feedback title shown in the end screen'
					>
					</input>
				</TextField>
				<TextField>
				Feedback				
					<textarea 
						onChange={(e) => setFeedback(e.target.value)}
						onBlur={onAnswerFieldBlur}
						style={{resize: 'vertical'}} 
						rows={4} 
						placeholder='feedback shown in the end screen'
						value={feedback}

					/>
				</TextField>
			</>}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.styles.padding}px;

	padding: ${p => p.theme.styles.padding}px;

	border: 2px solid ${p => p.theme.colors.neutralLightest};
	border-radius: ${p => p.theme.styles.borderRadius}px;
`;

// types

type AnswerFieldProps = NodeEditorProps & {
	answerData:  AnswerProps,
	answerId: string,
	index: number
}
