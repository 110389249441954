import { NodeEditorProps } from '../EditWindow';
import { NodesEditorContainer as Container } from '../EditWindowContainer';
import MessageField from './MessageField';
import AnswersField from './Answers/AnswersField';
import AnimationField from './AnimationField';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { MessageNode, nodesAtom } from '../../../atoms/flow';
import { useGetNodeWithId } from '../../../hooks/updateNodeHooks';
import ResetField from './ResetField';

const MessageEditWindow = ({selectedNodeId}: NodeEditorProps) => {
	const nodes = useAtomValue(nodesAtom);
	const getMessageNodeWithId = useGetNodeWithId<MessageNode>();

	const node = useMemo(() => {
		return getMessageNodeWithId(selectedNodeId);
	}, [selectedNodeId, nodes]);

	return(  
		<Container>
			{node && <MessageField {...node}/>}
			{node?.data.message_type === 0 &&
				<>
					<AnswersField {...node}/>
					<AnimationField {...node}/>
				</>}
			{node?.data.message_type === 1 &&
			<ResetField {...node}/>
			}
		</Container>
	);
};

// sc
 
export default MessageEditWindow;