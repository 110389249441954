import { AuthenticationData, authentication, createDirectus, realtime, rest } from '@directus/sdk';
import { Scenario } from '../atoms/menu';
import { Message, MessageAnswer, MessageAnswerTranslation, MessageTranslation, Ending, EndingTranslation, Stickynote, ScenarioTranslations, Sceneswap } from '../atoms/flow';
import { Character, CharacterTranslation, Language, ScenariosJunctionCharacters } from '../atoms/editor';

class LocalStorage {
	get() {
		return JSON.parse(localStorage.getItem('directus-data') ?? '{}');
	}
	set(data: AuthenticationData | null) {
		localStorage.removeItem('directus_data');
		localStorage.setItem('directus-data', JSON.stringify(data));
	}
}

export const directus = createDirectus<Schema>(import.meta.env.VITE_DIRECTUS_PROJECT_URL).with(realtime()).with(rest()).with(
	authentication(
		'json', 
		{ 
			credentials: 'include',
			autoRefresh: true,
			msRefreshBeforeExpires: 600000,
			storage: new LocalStorage(),
		},
	));

export const catchDirectusError = (tried: string, err: any) => {
	const error = tried+': '+JSON.stringify(err);
	console.error(error);
	window.dispatchEvent(new CustomEvent('directusError',{'detail': error}));
	throw new TypeError(error);

};

//returned by directus
export type Schema = {
	// languages 
	product_2_languages: Language[]

	// scenarios 
	product_2_scenarios: Scenario[]
	product_2_scenarios_translations: ScenarioTranslations[]


	//endings
	product_2_scenarios_endings: Ending[]
	product_2_scenarios_endings_translations: EndingTranslation[]

	// messages
	product_2_dialogues_messages: Message[]
	product_2_dialogues_messages_translations: MessageTranslation[]
	product_2_dialogues_messages_answers: MessageAnswer[]
	product_2_dialogues_messages_answers_translations: MessageAnswerTranslation[]

	// characters
	product_2_scenarios_product_2_characters: ScenariosJunctionCharacters[]
	product_2_characters: Character[]
	product_2_characters_translations: CharacterTranslation[]

	product_2_json_objects: string[];
	product_2_stickynotes: Stickynote[];
	product_2_scene_swaps: Sceneswap[];


}

export type Auditing = {
	user_created: string
	date_created: string
	user_updated: string
	date_updated: string
}
