import { css } from 'styled-components';

// url start at public folder

export const fonts = css`

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-Regular.ttf") format("truetype");
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-Italic.ttf") format("truetype");
		font-weight: 400;
		font-style: italic;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-Bold.ttf") format("truetype");
		font-weight: 700;
		font-style: normal;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-BoldItalic.ttf") format("truetype");
		font-weight: 700;
		font-style: italic;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-Black.ttf") format("truetype");
		font-weight: 900;
		font-style: normal;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-Light.ttf") format("truetype");
		font-weight: 200;
		font-style: normal;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-LightItalic.ttf") format("truetype");
		font-weight: 200;
		font-style: italic;
	}

	@font-face {
		font-family: "Poppins";
		src: url("./fonts/Poppins-Thin.ttf") format("truetype");
		font-weight: 100;
		font-style: italic;
	}

`;