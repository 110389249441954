
import { retrieveItemFromCollection, retrieveItemsFromCollection } from '../../tools/directusActions/read';
import { Message, MessageAnswer, MessageAnswerTranslation, MessageTranslation, Ending, EndingTranslation, FlowData, Stickynote, ScenarioTranslations, Sceneswap } from '../../atoms/flow';
import { Scenario } from '../../atoms/menu';

const retrieveFlowsData = async (scenarioId: number) => {
	if (!scenarioId) return;

	const scenario: Scenario = await retrieveItemFromCollection('product_2_scenarios', scenarioId, {fields: ['starting_message', 'name','environment', 'characters', 'translations','node_data', 'languages', 'scene_swaps']});
	const scenarioTranslations: { [id: number]: ScenarioTranslations } = await retrieveItemsFromCollection('product_2_scenarios_translations', {filter: {'product_2_scenarios_id' : {'_eq': scenarioId}}});

	const messages: { [id: number]: Message } = await retrieveItemsFromCollection('product_2_dialogues_messages', { filter: { 'scenario_id': { '_eq': scenarioId } } });
	const messagesTranslations: { [id: number]: MessageTranslation } = Object.keys(messages).length != 0 ? await retrieveItemsFromCollection('product_2_dialogues_messages_translations', { filter: { 'product_2_dialogues_messages_id': { '_in': Object.keys(messages) } } }) : {};
	
	const stickynotes: { [id: number]:  Stickynote} = await retrieveItemsFromCollection('product_2_stickynotes', { filter: { 'scenario_id': { '_eq': scenarioId }}});

	const answers: { [id: number]: MessageAnswer } = Object.keys(messages).length != 0 ? await retrieveItemsFromCollection('product_2_dialogues_messages_answers', { filter: { 'message_id': { '_in': Object.keys(messages) }}}) : {};
	const answerTranslations: { [id: number]: MessageAnswerTranslation } = Object.keys(answers).length != 0 ? await retrieveItemsFromCollection('product_2_dialogues_messages_answers_translations', { filter: { 'product_2_dialogues_messages_answers_id': { '_in': Object.keys(answers) } } }) : {};
	
	const endings: { [id: number]: Ending } = await retrieveItemsFromCollection('product_2_scenarios_endings', { filter: { 'scenario_id': { '_eq': scenarioId } } });
	const edningsTranslations: { [id: number]: EndingTranslation } = Object.keys(endings).length != 0 ? await retrieveItemsFromCollection('product_2_scenarios_endings_translations', { filter: { 'product_2_scenarios_endings_id': { '_in': Object.keys(endings) } } }) : {};
	
	const sceneswaps: { [id: number]: Sceneswap} = await retrieveItemsFromCollection('product_2_scene_swaps', { filter: { 'scenario_id': { '_eq': scenarioId }}});

	const data: FlowData = {

		//data of current scenario
		scenario: scenario,
		scenarioTranslations: scenarioTranslations,

		messages: messages,
		messagesTranslations: messagesTranslations,
		answers: answers,
		answerTranslations: answerTranslations,
		endings: endings,
		endingsTranslations: edningsTranslations,
		stickynotes: stickynotes,
		sceneswaps: sceneswaps
	};

	return data;
};


export default retrieveFlowsData;