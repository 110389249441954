import { useAtom } from 'jotai';
import { HTMLAttributes } from 'react';
import { NodeProps } from 'reactflow';
import styled, { css, useTheme } from 'styled-components';
import { enableMessageNumbersAtom } from '../../atoms/atoms';

const Node = (props: CustomNodeProps) => {
	const theme = useTheme();
	const [enableMessageNumber] = useAtom(enableMessageNumbersAtom);

	return (
		<Container {...props}>
			{enableMessageNumber ? <Title>{props.title ?? 'unnamed node'}</Title>: <div style={{height: `${theme.styles.borderWidth}px`}}></div>}
			<Content>
				{props.children}
			</Content>
		</Container>
	);
};

type CustomNodeProps = HTMLAttributes<HTMLDivElement> & NodeProps & {
	title?: string;
}

const Title = styled.h3`
	color: ${p => p.theme.colors.neutralLightest};
	font-weight: 500;
	margin:unset;
	margin-inline: ${p => p.theme.styles.padding}px;
	margin-block: ${p => p.theme.styles.padding / 2}px;
`;

const Container = styled.div<CustomNodeProps>`
	min-width: 350px;
	max-width: 500px;
	background-color: ${p => p.theme.colors.neutralLight};
	border-radius: ${p => p.theme.styles.borderRadius}px;

	display: flex;
	flex-direction: column;

	${p => p.selected && css`
		outline: solid ${p => p.theme.styles.borderWidth}px ${p => p.theme.colors.primary};
	`}
`;

const Content = styled.div`
	z-index: 0;
	background-color: ${p => p.theme.colors.neutralLightest};
	border-radius: ${p => p.theme.styles.borderRadius - 5}px;
	margin: ${p => p.theme.styles.borderWidth}px;
	padding: ${p => p.theme.styles.padding}px;
	display:flex;
	flex-direction: column;
	gap: ${p => p.theme.styles.padding}px;
	margin-block-start: 0;
`;

export default Node;