import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { storage } from './atoms';
import { Auditing } from '../tools/directus';
import { DirectusUser } from '@directus/sdk';

export const selectedScenarioIdAtom = atomWithStorage<number | undefined>('scenario_id', undefined, storage);
export const connectingFromHandle = atom<{nodeId: string, handleId: string} | undefined>(undefined);
export const connectionIsValid = atom(true);
export const dragCanvasAllowed = atom(true);

export const editorDataAtom = atom<EditorData | undefined>(undefined);

export type EditorData = {
	languages: Language[],
	animations: string[]
	moods: string[]
	characters: { [id: number]: Character; },
	characters_translations: { [id: number]: CharacterTranslation; };
	users: { [id: string]: DirectusUser<[]>}
	environments: {[id: string] : Environment}
}

export type Language = {
	code: string, 
	name: string, 
	direction: 'ltr' | 'rtl'
}

export type Environment = {
	id:string,
	title: string
}

export type Character = Auditing & {
	id: number,
	translations: number[];
	image: string;
};
export type ScenariosJunctionCharacters = Auditing & {
	id: number,
	product_2_scenarios_id: number;
	product_2_characters_id: number;
};

export type CharacterTranslation = {
	id: number,
	name: string,
	product_2_languages_code: string;
	product_2_characters_id: number;
};