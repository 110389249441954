import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { NodeProps } from 'reactflow';
import styled, { css } from 'styled-components';
import { useUpdateNodeData, useUpdateNodeValues } from '../../../hooks/updateNodeHooks';
import { useAtom, useAtomValue } from 'jotai';
import { dragCanvasAllowed, editorDataAtom } from '../../../atoms/editor';
import { textareaAutoGrow } from '../../../utilities/helpers';

export type StickynoteNodeData = {
	directus_id: number | null
	text: string
	color: string
	number: number
	createdBy: string
}

const StickynoteNode = (props: NodeProps<StickynoteNodeData>) => {
	
	const [, setDragAllowed] = useAtom(dragCanvasAllowed);
	const editorData = useAtomValue(editorDataAtom);

	const updateNodeData = useUpdateNodeData();
	const updateNodeValues = useUpdateNodeValues();
	const [text, setText] = useState<string>('');

	useEffect(() => {
		props.data.text && setText(props.data.text);
	}, []);

	const onBlur = () => {
		setDragAllowed(true);
		updateNodeValues(props.id, {field: 'draggable', newValue: true});
		updateNodeData(props.id, {field: 'text', newValue: text});
	};

	const disableDragging = () => {
		setDragAllowed(false);
		updateNodeValues(props.id, {field: 'draggable', newValue: false});
	};

	const textRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if(!textRef.current) return;
		textRef.current.style.height = textRef.current.scrollHeight+'px';
	}, [text]);

	return (
		<Container {...props}>
			<StyledTextarea
				ref={textRef}
				onBlur={onBlur}
				onFocus={disableDragging}
				placeholder='Type something...'
				value={text}
				onChange={(e) => {(textareaAutoGrow(e));setText(e.target.value);}}
				onKeyDown={(e) => {
					if(e.code === 'Enter' && !e.shiftKey){ e.preventDefault(); e.currentTarget.blur();}
				}}
			/>
			{editorData && editorData.users[props.data.createdBy] ? editorData.users[props.data.createdBy].email : 'No user found'}
		</Container>
	);
};

type CustomNodeProps = HTMLAttributes<HTMLDivElement> & NodeProps

const StyledTextarea = styled.textarea`
	width: 100%;
	outline: none;
	border: none;
	font-family: poppins;
	resize: none;
	background: none;
	overflow: hidden;
`;

const Container = styled.div<CustomNodeProps>`
	width: 200px;
	background-color: ${p => p.data.color};
	border-radius: ${p => p.theme.styles.borderRadius}px;
	padding: ${p => p.theme.styles.padding}px;
	aspect-ratio: 1/1;
	${p => p.selected && css`
		outline: solid ${p => p.theme.styles.borderWidth}px ${p => p.theme.colors.primary};
	`}

	& > p {
		padding: 0;
	}

	display: flex;
	flex-direction: column;
	justify-content: space-between;


	font-style: italic;
	color: ${p => p.theme.colors.neutral};
	font-size: 0.5em;

`;

export default StickynoteNode;