import { CSSProperties, HTMLAttributes, useMemo } from 'react';
import Row from '../components/NodeRow';
import { MessageNodeData } from './MessageNode';
import { useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../atoms/atoms';
import { AiFillMessage, AiOutlineMessage } from 'react-icons/ai';
import { editorDataAtom } from '../../../atoms/editor';
import styled from 'styled-components';
import ellie from '../../../assets/images/ellie_thinking_lowres.png';

const Message = ({data}: MessageProps) => {

	const editorData = useAtomValue(editorDataAtom);
	const language = useAtomValue(activeLanguageAtom);

	const text = useMemo(() => {
		const newText = data.translations[language];
		if(newText) return newText.text;
		else 'no translation found for message';
	},[data.translations, language]);

	const speakingCharacterName = useMemo(() => {
		if(data.speaking_character === null) return 'player';
		if(!editorData) return undefined;
		const character = editorData.characters[data.speaking_character];
		if(!character) return undefined;
		const characterTranslations: {[lng: string] : string} = {};
		character.translations.map((tr) => {characterTranslations[editorData.characters_translations[tr].product_2_languages_code] = editorData.characters_translations[tr].name;});
		return characterTranslations[language];
	},[editorData, data, language]);
	
	const overwriteName = useMemo(() => {
		const translations = data.translations[language];
		if(translations && translations.overwrite_name) return translations.overwrite_name;
		else return undefined;
	},[data.translations, language]);

	return (  
		<Row>
			{data.message_type === 0 && <h3>{speakingCharacterName === 'player' ? <AiOutlineMessage style={iconStyle} /> : <AiFillMessage style={iconStyle}/>}{overwriteName ?? speakingCharacterName}</h3>}
			{data.message_type === 1 && <h3 style={{display: 'flex', alignItems: 'center'}}><EllieIcon/> Ellie</h3>}
			
			<p>{text}</p>
		</Row>
	);
};

// sc
const EllieIcon = styled.svg`
	background: url(${ellie});
	background-size: cover;
	aspect-ratio: 1/1;
	height: 40px;
	display: block;
`;

// types

export type MessageProps = HTMLAttributes<HTMLDivElement> & {
	data: MessageNodeData
}

// data

const iconStyle : CSSProperties = {
	verticalAlign:'middle',
	fontSize: '1.5em',
	marginRight: '5px'
};

export default Message;