import { ObjectEntries } from '../../../atoms/atoms';
import { ScenarioSettings } from '../../../atoms/menu';
import { useAtomValue } from 'jotai';
import { editorDataAtom } from '../../../atoms/editor';
import { InputBox, Label } from './EditScenarioWindow';
import styled from 'styled-components';
import { IoIosCloseCircle } from 'react-icons/io';

const EditLanguages = ({scenarioSettings, changeScenarioSetting}:{scenarioSettings: ScenarioSettings, changeScenarioSetting: (change: ObjectEntries<ScenarioSettings>) => void}) => {
	const editorData = useAtomValue(editorDataAtom);

	return (
		<>
			<Label>Available languages</Label>
			<InputBox style={{display: 'flex'}}>
				<LanguagesContainer>
					{scenarioSettings.languages.map((e) => {
						return (
							<AddedLanguage key={e}>
								{e}
								{e != 'en' && <IoIosCloseCircle onClick={() => changeScenarioSetting({field: 'languages', newValue: scenarioSettings.languages.filter((lng) => lng != e )})} />}
							</AddedLanguage>
						);
					})}
				</LanguagesContainer>
				<select
					style={{background: 'none', border: 'none', outline: 'none', width: '15px', cursor: 'pointer'}}
					onChange={(e) => e.target.value && changeScenarioSetting({field: 'languages', newValue: [...scenarioSettings.languages, e.target.value]})}
				>
					<option></option>
					{editorData && Object.values(editorData.languages).filter((lng) => !scenarioSettings.languages.includes(lng.code)).map((lng) => {
						return (<option key={lng.code} value={lng.code}>{lng.name}</option>);
					})}
				</select>
			</InputBox>

		</>
	);
};

const LanguagesContainer = styled.div`
	width: 100%;
	display: grid; 
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: ${p => p.theme.styles.padding}px;
`;

const AddedLanguage = styled.div`
    background-color: ${p => p.theme.colors.neutralLight};
    padding: ${p => p.theme.styles.padding/2}px ${p => p.theme.styles.padding}px;

    border-radius: 25px;

    & > svg {
        margin-left: 10px;
        font-size: 1.5em;
        color: ${p => p.theme.colors.neutral};
        cursor: pointer;
    }

	display: flex;
	justify-content: space-evenly;
	text-transform: uppercase;

`;

export default EditLanguages;