import { useEffect, useRef } from 'react';
import ReactFlow, { Background, Connection, ConnectionLineType, Edge } from 'reactflow';
import 'reactflow/dist/style.css';
import MessageNode from './nodes/MessageNode/MessageNode';
import EndingNode from './nodes/EndingNode/EndingNode';
import { useAtom, useSetAtom } from 'jotai';
import { NodeType, edgesAtom, flowDataAtom, nodesAtom } from '../atoms/flow';
import { selectedScenarioIdAtom, connectingFromHandle, connectionIsValid, dragCanvasAllowed } from '../atoms/editor';
import retrieveFlowsData from '../data/retrieve/flowsData';
import { focusedWindowAtom } from '../Interface/FocusedWindows/Focused';
import { useLoadNodes } from '../data/Load/onLoad';
import { useOnConnect, useOnConnectEnd, useOnDragOver, useOnDrop, useOnEdgesChange, useOnEdgesDelete, useOnNodeDragStop, useOnNodesChange } from '../hooks/flowEventsHooks';
import StickynoteNode from './nodes/StickynoteNode/StickynoteNode';
import IntroductionNode from './nodes/IntroductionNode/IntroductionNode';
import CustomEdge from './edges/CustomEdge';
import SceneswapNode from './nodes/SceneswapNode/SceneswapNode';

export const nodeTypes: {[key in NodeType] : any} = {
	message: MessageNode,
	ending: EndingNode,
	stickynote: StickynoteNode,
	introduction: IntroductionNode,
	sceneswap: SceneswapNode
};

const edgeTypes = {
	custom: CustomEdge,
};

const Canvas = () => {
	//atoms
	const [dragAllowed] = useAtom(dragCanvasAllowed);
	const [scenario,] = useAtom(selectedScenarioIdAtom);
	const [nodes] = useAtom(nodesAtom);
	const [edges] = useAtom(edgesAtom);

	const [, setFlowsData] = useAtom(flowDataAtom);
	const setFocusedWindow = useSetAtom(focusedWindowAtom);

	//hooks
	const onNodesChange = useOnNodesChange();
	const onEdgesDelete = useOnEdgesDelete();
	const onEdgesChange = useOnEdgesChange();
	const connect = useOnConnect();
	const connectEnd = useOnConnectEnd();
	const onDrop = useOnDrop();
	const onDragOver = useOnDragOver();
	const onNodeDragStop = useOnNodeDragStop();
	const loadNodes = useLoadNodes();

	useEffect(() => {
		if (!scenario) return;
		setFocusedWindow('loading');
		retrieveFlowsData(scenario).then( async (newFlowsData) => {
			if (!newFlowsData) return;
			await loadNodes(newFlowsData); //add nodes and edges to the flow
			setFlowsData(newFlowsData);	
			setFocusedWindow(undefined);
			
		});
	}, [scenario]);

	const connectionCreated = useRef(false);
	const setConntecting = useSetAtom(connectingFromHandle);
	const [isValid] = useAtom(connectionIsValid);

	const onConnectStart = (event: any, {nodeId, handleId} : {nodeId: string | null, handleId: string | null}) => {
		event;
		connectionCreated.current = false;
		setConntecting(nodeId && handleId ? {nodeId: nodeId, handleId: handleId } : undefined);
	};

	const onConnect = (newEdge: Edge | Connection) => {
		connectionCreated.current = true;
		connect(newEdge);
		setConntecting(undefined);
	};

	const onConnectEnd = (event: MouseEvent | TouchEvent) => {
		if (!connectionCreated.current && isValid) {
			connectEnd(event);
		}
	};

	return (
		<>
			<ReactFlow
				fitView
				minZoom={0.3}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				nodes={nodes}
				edges={edges}
				panOnDrag={dragAllowed}
				snapToGrid
				elevateNodesOnSelect
				onNodesChange={onNodesChange}
				onEdgesDelete={onEdgesDelete}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				onConnectStart={onConnectStart}
				onConnectEnd={onConnectEnd}
				onDrop={onDrop}
				onDragOver={onDragOver}
				onNodeDragStop={onNodeDragStop}
				connectionLineType={ConnectionLineType.SmoothStep}
				defaultEdgeOptions={{ type: ConnectionLineType.SmoothStep, interactionWidth: 50 }}
			>
				<Background variant={undefined} gap={30} size={2} style={{ backgroundColor: 'hsla(0, 0%, 87%, 1)'}}/>
			</ReactFlow>
		</>
	);
};

export default Canvas;
