import { NodeProps, Position } from 'reactflow';
import { useMemo,  } from 'react';
import FollowUpMessageHandle from '../../handles/FollowUpMessageHandle';
import Node from '../Node';
import Answers from './MessageNodeAnswers';
import Message from './MessageNodeMessage';
import { GameEvent } from '../../../atoms/flow';

export type MessageNodeData = {
	directus_id: number | null
	translations: MessageTranslations,
	follow_up_message: number | null,
	events: GameEvent[],
	ending: number | null,
	message_type: 0 | 1
	answers: {[id: string]: Answer},
	speaking_character: number | null,
	reset: boolean
}

export type MessageTranslations = {[lng: string] : {
	directus_id: number | null,
	text: string,
	overwrite_name: string,
}}

export type Answer = {
	directus_id: number | null 
	follow_up_message: number | null,
	correctness: 0 | 1 | 2 | null,
	conditional_requirement: number | null
	translations: AnswerTranslations
}

export type AnswerTranslations = {[lng: string] : {
	directus_id: number | null,
	text: string,
	feedback_title: string,
	feedback_body: string
}}

const MessageNode = (props: NodeProps<MessageNodeData>) => {
	const sourceHandleId = useMemo(() => props.id ? `source-${props.id}` : undefined, [props.id]);
	const targetHandleId = useMemo(() => props.id ?`target-${props.id}` : undefined, [props.id]);

	const answers = useMemo(() => {
		return props.data.answers;
	},[props.data.answers]);

	return ( 
		<Node 
			{...props} 
			style={props.data.message_type === 1 ? {backgroundColor: '#66b3ff'} : {}} 
			title={`${props.data.message_type === 1 ? 'context - ' : 'message - '}`+(props.data.directus_id ? props.data.directus_id.toString() : '')}>

			{targetHandleId && <FollowUpMessageHandle type="target" position={props.data.reset ? Position.Right : Position.Left} id={targetHandleId}/>}
			{Object.keys(props.data.answers).length < 1 && sourceHandleId &&
				<FollowUpMessageHandle type="source" position={props.data.reset ? Position.Left : Position.Right} id={sourceHandleId} 
					isConnectable={props.data.follow_up_message === null && props.data.ending === null && !props.data.events.find((e) => e.type === 'change-scene')}/>
			}

			<Message data={props.data}/>

			<Answers answers={answers}/>
		</Node>
	);
};

export default MessageNode;