import styled from 'styled-components';
import { ObjectEntries } from '../../../atoms/atoms';
import { ScenarioSettings } from '../../../atoms/menu';
import { InputBox, Label } from './EditScenarioWindow';
import { Input } from '../../Interactables/Input';

const EditName = ({scenarioSettings, changeScenarioSetting}:{scenarioSettings: ScenarioSettings, changeScenarioSetting: (change: ObjectEntries<ScenarioSettings>) => void}) => {
	return (
		<> 			
			<Label>Name</Label>
			<InputBox>
				<StyledInput 
					placeholder='Type the name here...' 
					value={scenarioSettings.name} 
					onChange={(e) => changeScenarioSetting({field: 'name', newValue: e.target.value})}
				/>
			</InputBox>
		</>
	);
};

const StyledInput = styled(Input)`
    width: 100%;
`;
 
export default EditName;