import { updateItem } from '@directus/sdk';
import { catchDirectusError, directus } from '../directus';
import { ObjectEntries } from '../../atoms/atoms';
import { Answer, AnswerTranslations, MessageTranslations } from '../../Editor/nodes/MessageNode/MessageNode';
import { NodeType, collections } from '../../atoms/flow';
import { changeOptions } from '../../hooks/updateNodeHooks';
import { Scenario } from '../../atoms/menu';
import { IntroductionTranslations } from '../../Editor/nodes/IntroductionNode/IntroductionNode';

export const updateNodeInDirectus = (type: NodeType, directusId: number, change: changeOptions) => {
	return directus.request(updateItem(collections[type], directusId, {[change.field] : change.newValue} )).catch((err: any) => {
		catchDirectusError(` update ${type} node ${directusId}`, err);
	});
};

export const updateAnswerInDirectus = (directusId: number, change: ObjectEntries<Answer>) => {
	return directus.request(updateItem('product_2_dialogues_messages_answers', directusId, {[change.field] : change.newValue} )).catch((err: any) => {
		catchDirectusError(` update answer ${directusId}`, err);
	});
};

export const updateMessageTranslationInDirectus = (directusId: number, newTexts: MessageTranslations[keyof MessageTranslations]) => {

	return directus.request(updateItem('product_2_dialogues_messages_translations', directusId,{
		text: newTexts.text,
		overwrite_name: newTexts.overwrite_name
	})).catch((err: any) => {
		catchDirectusError(`update message translation ${directusId}`, err);
	});
};

export const updateEndingTranslationsInDirectus = (directusId: number, newText: string) => {

	return directus.request(updateItem('product_2_scenarios_endings_translations', directusId,{		
		description: newText,
	})).catch((err: any) => {
		catchDirectusError(`update ending translation ${directusId}`, err);
	});
};

export const updateAnswerTranslationsInDirectus = (directusId: number, newTexts: AnswerTranslations[keyof AnswerTranslations]) => {

	return directus.request(updateItem('product_2_dialogues_messages_answers_translations', directusId,{		
		text: newTexts.text,
		feedback_title: newTexts.feedback_title,
		feedback_body: newTexts.feedback_body,
	})).catch((err: any) => {
		catchDirectusError(`update answer translation ${directusId}`, err);
	});
};

export const updateScenarioInDirectus = (directusId: number, data: Partial<Scenario>) => {
	
	return directus.request(updateItem('product_2_scenarios', directusId, data )).catch((err: any) => {
		catchDirectusError(` update answer ${directusId}`, err);
	});
};

export const updateScenarioTranslationsInDirectus = (directusId: number, newTexts: IntroductionTranslations[keyof IntroductionTranslations]) => {

	return directus.request(updateItem('product_2_scenarios_translations', directusId,{		
		name: newTexts.name,
		description: newTexts.description,
		ellie_description: newTexts.ellie_description
	})).catch((err: any) => {
		catchDirectusError(`update scenario translation ${directusId}`, err);
	});
};
