import styled from 'styled-components';
import logoImage from '../assets/images/ellie_happy_lowres.png';

export const Logo = styled.div`
	background-image: url(${logoImage});
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	height: 200px;
	aspect-ratio: 1/1;
`;