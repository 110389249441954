import { readItems } from '@directus/sdk';
import { directus } from '../../tools/directus';
import { retrieveDirectusFiles, retrieveDirectusUsers, retrieveItemFromCollection, retrieveItemsFromCollection } from '../../tools/directusActions/read';
import { EditorData } from '../../atoms/editor';

const retrieveEditorData = async() => {
	// retrieve languages
	const languages = await directus.request(readItems('product_2_languages'));

	const animations = await retrieveItemFromCollection('product_2_json_objects', 'animations',{});
	const moods = await retrieveItemFromCollection('product_2_json_objects', 'moods',{});

	const characters = await retrieveItemsFromCollection('product_2_characters', {});
	const charactersTranslations = await retrieveItemsFromCollection('product_2_characters_translations', {});

	const users = await retrieveDirectusUsers();
	const environments = await retrieveDirectusFiles({filter: {'folder': {'_eq':'c719a232-334e-4332-b6aa-83664ad0feeb'}, 'title':{'_ncontains':'DRAFT'}}, fields: ['id', 'title']});


	const data: EditorData = {
		languages: languages,

		animations: animations.object,

		moods: moods.object,

		characters: characters,
		characters_translations: charactersTranslations,

		environments: environments,

		users: users
	};

	return data;
};

export default retrieveEditorData;