import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectedScenarioIdAtom } from './atoms/editor';

const Redirect = () => {

	const params = useParams<{scenarioId: undefined | string}>();

	const navigate = useNavigate();

	const setSelectedScenarioId = useSetAtom(selectedScenarioIdAtom);

	const scenarioId = useMemo(() => {
		if(!params.scenarioId) return undefined;
		return parseInt(params.scenarioId);
	},[params]);


	useEffect(() => {
		if(scenarioId !== undefined) {
			setSelectedScenarioId(scenarioId);
			navigate('/', {replace: true});
		}
	}, [scenarioId]);

	return ( 
		<>
		</>
	);
};
 
export default Redirect;