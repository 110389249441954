import { useEffect, useMemo, useState } from 'react';
import { useGetNodeWithId, useUpdateNodeData } from '../../../hooks/updateNodeHooks';
import { Label } from '../../misc/Label';
import { NodeEditorProps } from '../EditWindow';
import { NodesEditorContainer as Container } from '../EditWindowContainer';
import { MessageNode, nodesAtom, SceneswapNode } from '../../../atoms/flow';
import { useAtomValue } from 'jotai';

const SceneswapEditWindow = ({selectedNodeId}: NodeEditorProps) => {

	const updateNodeData = useUpdateNodeData();
	const nodes = useAtomValue(nodesAtom);
	const getSceneswapNodeWithId = useGetNodeWithId<SceneswapNode>();
	const getMessageNodeWithId = useGetNodeWithId<MessageNode>();

	const node = useMemo(() => {
		return getSceneswapNodeWithId(selectedNodeId);
	}, [selectedNodeId, nodes]);

	const [sceneId, setSceneId] = useState<string>('');

	useEffect(() => {
		if(node) setSceneId(node.data.scene_id);
	}, [node]);

	const onBlur = () => {

		node?.data.source_message_ids.map((id) => {
			const node = getMessageNodeWithId(`message${id}`);	if(!node) return; 
			node.data.events = node.data.events.map(event => {
				if (event.type === 'change-scene') 
					event = {...event, scene_id: sceneId};
				return event;
			});
			updateNodeData(`message${id}`,{field: 'events',newValue: node.data.events});
		});

		updateNodeData(selectedNodeId, {field: 'scene_id', newValue: sceneId});
	};

	return (  
		<Container>
			<Label>Id of the next scene
				<input 
					onBlur={onBlur}
					type={'text'}
					onChange={(e) => setSceneId(e.target.value)}
					value={sceneId}
				/>
			</Label>

		</Container>
	);
};
 
export default SceneswapEditWindow;