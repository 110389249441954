import styled from 'styled-components';
import { Pill } from '../Pill';

export const LoginInput = styled.input`
	${Pill}
	border: none;

	background-color: ${p => p.theme.colors.neutralLighter};
`;

export const Input = styled.input`
	border: none;
	outline: none;
	background-color: ${p => p.theme.colors.neutralLighter};
	font-family: 'Poppins', sans-serif;
`;
