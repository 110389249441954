import { HTMLAttributes, useEffect, useState } from 'react';
import { Connection, Handle, HandleProps, Position} from 'reactflow';
import styled, { css } from 'styled-components';
import { useConnectEdge } from '../../hooks/edgeHooks';
import { FaArrowRightFromBracket, FaArrowRightToBracket } from 'react-icons/fa6';
import { useAtom } from 'jotai';
import { connectionIsValid } from '../../atoms/editor';

const FollowUpMessageHandle = (props: FollowUpMessageHandleProps) => {

	const onConnect = useConnectEdge();
	const [hovering, setHovering] = useState(false);
	const [, setIsValid] = useAtom(connectionIsValid);

	useEffect(() => {
		if(!hovering)
			setIsValid(true);
	}, [hovering]);

	const isValidConnection = (connection: Connection) => {
		const notConnectableToEdning = ['answer', 'sceneswap', 'introduction'];
		const notConnectableToSceneswap = ['answer', 'sceneswap', 'introduction'];

		const valid = (
			(notConnectableToEdning.filter((n) => connection.sourceHandle?.includes(n)).length != 0 && connection.target?.includes('ending')) || //rule 1
			(notConnectableToSceneswap.filter((n) => connection.sourceHandle?.includes(n)).length != 0 && connection.target?.includes('sceneswap')) || //rule 2
			connection.target === connection.source//rule 3
		) ? false : true;

		setIsValid(valid);
		return valid;
	};


	return ( 
		<>
			<StyledHandle {...props}
				onMouseEnter={() => setHovering(true)} 
				onMouseLeave={() => setHovering(false)} 
				isValidConnection={isValidConnection}  
				onConnect={onConnect}>
				{props.type === 'target' ? 
					<FaArrowRightToBracket />:
					<FaArrowRightFromBracket />
				}
			</StyledHandle>
		</>
	);
};

const StyledHandle = styled(Handle)<FollowUpMessageHandleProps>`
	height: 24px;
	width: 24px;

	border: solid 2px ${p =>p.theme.colors.neutralLight};
	background-color: white;

	& > svg {
		height:100%;
		width:100%;
		rotate: -90deg;
		pointer-events: none;
	};

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	color: ${p => p.theme.colors.neutral};

	${p => !p.isConnectable && css`
		color: ${p => p.theme.colors.neutralLightest};
	`}

	background-color: ${p => (p.isConnectable || p.isConnectable === undefined) ? p.theme.colors.neutralLightest: p.theme.colors.neutralLight};
	color: ${p => (p.isConnectable || p.isConnectable === undefined) ? p.theme.colors.neutral: p.theme.colors.neutralLightest};

	${p => p.position === Position.Left && css`
		inset-inline-start: ${p => p.theme.styles.borderWidth /2}px;
		top:50%;
		translate: -50% 0;
	`}

	${p => p.position === Position.Right && css`
		inset-inline-end: ${p => p.theme.styles.borderWidth / 2}px;
		top:50%;
		translate: 50% 0;
	`}


`;

type FollowUpMessageHandleProps = HandleProps & HTMLAttributes<HTMLDivElement> & {
//
}
 
export default FollowUpMessageHandle;