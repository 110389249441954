import styled from 'styled-components';

export const Row = styled.div`
	position: relative;

	& > * {
		margin: 0;
		padding: 0;
	}
`;

export default Row;