import { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { Scenario, menuDataAtom, recentScenariosAtom, searchedScenarioAtom } from '../../atoms/menu';
import retrieveMenuData from '../../data/retrieve/menuData';
import Loader from '../misc/Loader';
import styled from 'styled-components';
import { catchDirectusError } from '../../tools/directus';
import OpenScenarioTile from './OpenScenarioTile';
import { selectedScenarioIdAtom } from '../../atoms/editor';
import CreateScenarioTile from './CreateScenarioTile';
import Pagination from './pagination';

const Menu = () => {

	const [loading, setLoading] = useState(false);
	const [menuData, setMenuData] = useAtom(menuDataAtom);
	const [selectedScenarioId] = useAtom(selectedScenarioIdAtom);
	const [searchedScenario] = useAtom(searchedScenarioAtom);
	const [recentScenarios, setRecentScenarios] = useAtom(recentScenariosAtom);
	const [pageScenarios, setPageScenarios] = useState<Scenario[]>([]);

	const retrieveData = async () => {
		setLoading(true);
		retrieveMenuData().then((newMenuData) => {
			setMenuData(newMenuData);
			setLoading(false);
		}).catch((e: any) => {
			catchDirectusError('error retrieving menuData', e);
		});
	};

	useEffect(() => {
		if(!selectedScenarioId)
			retrieveData();
		else {// set as recent
			let recent = recentScenarios;

			if(recent.includes(selectedScenarioId)){
				recent = recent.filter((i) => i != selectedScenarioId);
			}
			
			recent.unshift(selectedScenarioId);
			
			if(recent.length === 4) //dont let the list be longer than 3
				recent.pop();
	
			setRecentScenarios(recent);
		}

	}, [selectedScenarioId]);

	const scenarios = useMemo(() => {
		if(!menuData) return [];
		if(!searchedScenario) return Object.values(menuData.scenarios);
		return Object.values(menuData.scenarios).filter((scenario) => (
			scenario.name.includes(searchedScenario) || scenario.name.toLowerCase().includes(searchedScenario) || scenario.name.toUpperCase().includes(searchedScenario)
		));
	},[searchedScenario, menuData]);

	return ( 
		<>
			{!selectedScenarioId && menuData && <Container>
				{ loading ? <LoadContainer><Loader/></LoadContainer> :
					<>
						{!searchedScenario &&
						<>
							<h2 style={{marginLeft: '315px'}}> Recently opened</h2>
							<ScenariosContainer>
								<CreateScenarioTile/>
								{recentScenarios.map((id: number) => {
									const scenario = menuData.scenarios[id];
									if(scenario)
										return (
											<OpenScenarioTile key={`scenario-${id}`} scenario={scenario}></OpenScenarioTile>
										);
								})}
							</ScenariosContainer>
						</>
						}
						<h2>{searchedScenario ? 'Search results for: '+searchedScenario : 'All scenarios'}</h2>
						<ScenariosContainer>
							{pageScenarios.map((scenario) => {
								if(scenario)
									return (
										<OpenScenarioTile key={`scenario-${scenario.id}`} scenario={scenario}></OpenScenarioTile>
									);
							})}
						</ScenariosContainer>
						<Pagination 
							items={scenarios}
							pageLimit={18}
							setPageItems={setPageScenarios}
						/>
					</>}
			</Container>}
		</>
	);
};


const Container = styled.div`
    pointer-events: all;
    height: 100%;
	background-color: ${p => p.theme.colors.neutralLighter};
    z-index: 1;
    padding: ${p => p.theme.styles.padding*2}px;
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.styles.padding*2}px;
	overflow: auto;
`;

const ScenariosContainer = styled.div`
    display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: ${p => p.theme.styles.padding*2}px;
`;

const LoadContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Menu;