import styled from 'styled-components';

export const Tile = styled.div`
    width: 100%;
	height: 100%;
	aspect-ratio: 1/1;
	padding: ${p => p.theme.styles.padding}px;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: column;

`;