import { useAtomValue } from 'jotai';
import styled, { css } from 'styled-components';
import { editorDataAtom } from '../../../atoms/editor';
import { FaCheck } from 'react-icons/fa6';
import { ScenarioSettings } from '../../../atoms/menu';
import { ObjectEntries } from '../../../atoms/atoms';
import { Label } from './EditScenarioWindow';

const EditCharacters = ({scenarioSettings, changeScenarioSetting}:{scenarioSettings: ScenarioSettings, changeScenarioSetting: (change: ObjectEntries<ScenarioSettings>) => void}) => {
	const editorData = useAtomValue(editorDataAtom);

	return ( 
		<>
			<Label>Characters</Label>
			<Characters>
				{editorData && Object.values(editorData.characters).map((c) => {
					return (
						<CharacterPortrait
							key={`char-${c.id}`}
							onClick={() => {
								!scenarioSettings.characters.includes(c.id) ?
									changeScenarioSetting({field: 'characters', newValue: [...scenarioSettings.characters, c.id]})
									:
									changeScenarioSetting({field: 'characters', newValue: scenarioSettings.characters.filter((e) => e != c.id)});
							}} 
							selected={scenarioSettings.characters.includes(c.id)} $img={`https://tqwi.directus.app/assets/${c.image}`}
						>
							{scenarioSettings.characters.includes(c.id) && <FaCheck />}
						</CharacterPortrait>
					);
				})}
			</Characters>
		</>
	);
};

const Characters = styled.div`
    display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-gap: ${p => p.theme.styles.padding*2}px;
`;

const CharacterPortrait = styled.div<{$img: string, selected: boolean}>`
	background-image: url(${p => p.$img});
	background-size: cover;
	background-position: top;
	border-radius: 100%;
	aspect-ratio: 1/1;
	background-color: ${p => p.theme.colors.neutralLighter};
	cursor: pointer;

	${p => p.selected && css`
        outline: 3px solid ${p => p.theme.colors.primary};
    `}

	color: ${p => p.theme.colors.primary};
	font-size: 3em;
	display: flex;
	justify-content: center;
	align-items: center;
`;
 
 
export default EditCharacters;