import styled from 'styled-components';
import { Pill } from '../Pill';
import { HTMLAttributes } from 'react';
import { css } from 'styled-components';

export const Button = styled.button<ButtonProps>`

	position: relative;


	border: none;
	outline: none;	
	${Pill}
	background-color: ${p => p.theme.colors.primary};
	color: ${p => p.theme.colors.neutralLightest};

	pointer-events: auto;
	cursor: pointer;
	flex-shrink: 0;
	flex-grow: 0;

	&:disabled:before{
		content: '';
		position: absolute; inset:0;
		background-color: rgba(0,0,0,.4);
		border-radius: inherit;
	}

	&:disabled {
		pointer-events: none;
	}

	&:hover:before{
		content: '';
		position: absolute; inset:0;
		background-color: ${p => p.theme.colors.primaryLighter};
		border-radius: inherit;
	}

	${p => p.type == 'outlined' && css`
		background-color: ${p => p.theme.colors.neutralLightest};
		color: ${p => p.theme.colors.primary};
		border: 2px solid ${p => p.theme.colors.primary};;
	`}

	text-align: center;
	font-weight: 600;
`;

type ButtonProps = HTMLAttributes<HTMLButtonElement> & {
	type?: 'outlined' | 'submit' //default is filled
}

export const IconButton = styled(Button)<{size?: ButtonSize}>`

	position: relative;

	width:${p => {		
		switch(p.size){
		case 'large': return '70px';
		case 'medium': return '50px';
		case 'small': return '30px';
		default: return '70px';
		}
	}};

	aspect-ratio: 1/1;

	display: flex;
	justify-content: center;
	align-items: center;

	flex-shrink: 0;
	flex-grow: 0;
	flex-basis: 1;
	padding: 0;

	& > svg {
		position: absolute;
		left:50%; top:50%;
		translate: -50% -50%;
		width: 70%;
		height: 70%;
		
		&:hover * {
			fill: ${p => p.theme.colors.primary};
		}
	}

	border-radius: 50em;


`;

type ButtonSize = 'large' | 'medium' | 'small';