import Loader from '../misc/Loader';

const LoadingWindow = () => {
	return (  
		<>
			<Loader style={{position: 'absolute', inset: '50%', translate: '-50% -50%'}} />
		</>
	);
};
 
export default LoadingWindow;