import styled from 'styled-components';
import EditWindow from '../EditWindow/EditWindow';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import NodesToolbar from './NodesToolbar';

const BottomRow = () => {
	return (  
		<Container>
			<LanguageSelect/>
			<NodesToolbar/>
			<EditWindow/>
			<LanguageSelect style={{visibility: 'hidden', pointerEvents: 'all'}}/>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	z-index: 0;
	display: flex;
	flex-grow: 1;
	align-items: flex-end;
	padding: ${p => p.theme.spacing.gap}px;
`;
 
export default BottomRow;