
import styled from 'styled-components';
import MessageIcon from '../../assets/icons/NodeMessageIcon';
import EndingIcon from '../../assets/icons/NodeEndingIcon';
import ContextIcon from '../../assets/icons/NodeContextIcon';
import StickynoteIcon from '../../assets/icons/NodeStickynoteIcon';
import { NodeType } from '../../atoms/flow';
import { IconButton } from '../Interactables/Buttons';
import SceneswapIcon from '../../assets/icons/NodeSceneswapIcon';



const NodesToolbar = () => {

	const onDragStart = (event: React.DragEvent<HTMLButtonElement>, nodeType: NodeType | 'context') => {
		event.dataTransfer.setData('application/reactflow', nodeType);
		event.dataTransfer.effectAllowed = 'move';
	};

	return (  
		<Container>
			<StyledIconButton draggable onDragStart={(e) => onDragStart(e, 'message')} title={'message'}>
				<MessageIcon/>
			</StyledIconButton>
			<StyledIconButton draggable onDragStart={(e) => onDragStart(e, 'context')} title={'context'}>
				<ContextIcon/>
			</StyledIconButton>
			<StyledIconButton draggable onDragStart={(e) => onDragStart(e, 'stickynote')} title={'sticky note'}>
				<StickynoteIcon/>
			</StyledIconButton>
			<StyledIconButton draggable onDragStart={(e) => onDragStart(e, 'ending')} title={'ending'}>
				<EndingIcon/>
			</StyledIconButton>
			<StyledIconButton draggable onDragStart={(e) => onDragStart(e, 'sceneswap')} title={'scene swap'}>
				<SceneswapIcon/>
			</StyledIconButton>
		</Container>
	);
};

// sc

const Container = styled.div`

	pointer-events: all;


	display: flex;
	gap: 10px;
	align-items: center;
	
	margin-inline: auto;
	height: fit-content;

	padding: ${p => p.theme.styles.padding}px;
	

    transition: all 0.5s ease-in-out;

    background-color: ${p => p.theme.colors.neutralLighter};
	border-radius: ${p => p.theme.styles.borderRadius}px;

	box-shadow: ${p => p.theme.styles.boxShadow};
    
`;

const StyledIconButton = styled(IconButton)`
	background-color: transparent;
`;
 
export default NodesToolbar;