import { useContext, useMemo } from 'react';
import {BaseEdge, EdgeLabelRenderer, EdgeProps,	getSmoothStepPath} from 'reactflow';
import { nodesAtom } from '../../atoms/flow';
import { useAtom } from 'jotai';
import { ThemeContext } from 'styled-components';
import { useGetNodeWithId } from '../../hooks/updateNodeHooks';

const CustomEdge = (props: EdgeProps) => {

	const [edgePath, labelX, labelY] = getSmoothStepPath({
		sourceX: props.sourceX,
		sourceY: props.sourceY,
		sourcePosition: props.sourcePosition,
		targetX: props.targetX,
		targetY: props. targetY,
		targetPosition: props.targetPosition,
	});

	const [nodes] = useAtom(nodesAtom);
	const themeContext = useContext(ThemeContext);
	const getNodeWithId = useGetNodeWithId();

	const sourceNode = useMemo(() => {
		return getNodeWithId(props.source);
	},[nodes, props.source]);

	const targetNode = useMemo(() => {
		return getNodeWithId(props.target);
	},[nodes, props.target]);

	return (
		<>
			<BaseEdge style={{
				stroke: props.selected || sourceNode?.selected || targetNode?.selected ? themeContext?.colors.primary : '',
			}} path={edgePath} markerEnd={props.markerEnd}/>
			<EdgeLabelRenderer>
				<div
					style={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						pointerEvents: 'all',
					}}
				>
					{/* label */}
				</div>
			</EdgeLabelRenderer>
		</>
	);
};

export default CustomEdge;
