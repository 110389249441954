import { HTMLAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

const Loader = (props: HTMLAttributes<HTMLDivElement>) => {
	return (  
		<_Loader {...props}><div></div><div></div></_Loader>
	);
};

// sc

const ldsRipple = keyframes`
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`;

const _Loader = styled.div`
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  inset: 0;
	& > div {
		position: absolute;
    left:50%;top:50%;
    translate: -50% -50%;
		border: 4px solid ${p => p.theme.colors.neutral};
		opacity: 1;
		border-radius: 50%;
		animation: ${ldsRipple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
	}

	& > div:nth-child(2) {
		animation-delay: -0.5s;
	}
`;
 
export default Loader;