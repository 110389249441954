import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import { useAtom } from 'jotai';
import { nodesAtom } from '../../../atoms/flow';

const LanguageOption = ({value} : {value: string}) => {
	const [nodes] = useAtom(nodesAtom);

	const translatedProgress = useMemo<{percentage: number, todo: string[]}>(() => {
		let count = 0;
		const todoList: string[] = [];
		const translatableNodes = nodes.filter((n) => n.type != 'stickynote' && n.type != 'sceneswap');


		translatableNodes.map((node) => {
			let answersTranslated = 0;

			switch(node.type){
			case 'message':
				Object.values(node.data.answers).map((answer) => { 

					if(answer.correctness != null){
						if(answer.translations[value] && answer.translations[value].text && answer.translations[value].feedback_title && answer.translations[value].feedback_body){
							answersTranslated += 1;
						}
					} 
					else if(answer.translations[value] && answer.translations[value].text)
						answersTranslated += 1;
				});
				//check if all answers and the message self are translated
				if(answersTranslated === Object.values(node.data.answers).length && node.data.translations[value] && node.data.translations[value].text)
					count += 1;
				else todoList.push(node.id);
				break;
			case 'introduction':
				if(node.data.translations[value] && node.data.translations[value].name && 
                    node.data.translations[value].description && node.data.translations[value].ellie_description)
					count += 1;
				else todoList.push('introduction fields');
				break;
			case 'ending':
				if(node.data.translations[value] && node.data.translations[value].description)
					count += 1;
				else todoList.push(node.id);
				break;

			}


		}); 
		return {percentage: Math.round(count === 0 ? 0 : (count / translatableNodes.length) * 100), todo: todoList};
	},[nodes, value]);
    
	return ( 
		<Option title={translatedProgress.todo.toString().split(',').join('\n')} value={value} key={`language-option-${value}`}>
			{value} {translatedProgress.percentage+'%'}
		</Option>
	);
};

const Option = styled.option`
`;

export default LanguageOption;