import { useAtom, useSetAtom } from 'jotai';
import { Answer, AnswerTranslations } from '../Editor/nodes/MessageNode/MessageNode';
import { MessageNode, edgesAtom, nodesAtom } from '../atoms/flow';
import { useDeleteEgde } from './edgeHooks';
import { createAnswerInDirectus, createAnswerTranslationInDirectus } from '../tools/directusActions/create';
import { useGetNodeWithId } from './updateNodeHooks';
import { ObjectEntries, activeLanguageAtom } from '../atoms/atoms';
import { updateAnswerInDirectus, updateAnswerTranslationsInDirectus } from '../tools/directusActions/update';
import { deleteAnswerInDirectus } from '../tools/directusActions/delete';

export const useAddNewAnswer = () => {
	const [edges,] = useAtom(edgesAtom);
	const [, setNodes] = useAtom(nodesAtom);
	const deleteEgde = useDeleteEgde();
	const getNodeWithId = useGetNodeWithId<MessageNode>();

	return (nodeId: string) => {
		const node = getNodeWithId(nodeId);
		if(!node) return;

		//remove edges connected to the source
		edges.map(edge => {
			if(edge.source === nodeId && !edge.sourceHandle?.includes('answer')) deleteEgde(edge);
		});
		
		createAnswerInDirectus(node.data).then((request: any) => {
			setNodes((nodes) => nodes.map(node => {
				//store answer and build up data
				if(node.id === nodeId && node.type === 'message') {
					node.data = {...node.data, 
						answers: {...node.data.answers, 
							['answer' + request.id.toString()] : {//newanswer
								directus_id: request.id,
								follow_up_message: null,
								correctness: null,
								conditional_requirement: null,
								translations: {}
							}
						}
					};
				}
				return {...node};
			}));
		
		});
	};
};

export const useUpdateNodeAnswer = () => {
	const setNodes = useSetAtom(nodesAtom);
	const updateAnswerTranslationsData = useUpdateAnswerTranslationsData();

	return (nodeId: string, answerId: string, change: ObjectEntries<Answer>, alsoUpdateDirectus?: boolean) => {
		setNodes((nodes) => nodes.map(node => {
			if(node.id === nodeId && node.type === 'message' && node.data.answers[answerId]) {
				if(change.field === 'translations') updateAnswerTranslationsData(node,answerId,change.newValue);
				else if(alsoUpdateDirectus != false && node.data.answers[answerId].directus_id) updateAnswerInDirectus(node.data.answers[answerId].directus_id, change);
				node.data.answers[answerId] = {...node.data.answers[answerId], [change.field] : change.newValue};
				node.data = { ...node.data};
			}
			return {...node};
		}));
	};
};

export const useUpdateAnswerTranslationsData = () => {
	const [, setNodes] = useAtom(nodesAtom);
	const [language, ] = useAtom(activeLanguageAtom);

	return (updatedNode: MessageNode,answerId: string, newData: AnswerTranslations) => {

		const updateTranslationId = (id: number) => {
			setNodes((nodes) => nodes.map(node => {
				if (node.id === updatedNode.id && node.type === 'message') {
					newData[language].directus_id = id;
					node.data.answers[answerId] = {...node.data.answers[answerId], 'translations' : newData};
					node.data = { ...node.data};
				}
				return {...node};
			}));		
		};

		if(updatedNode.data.answers[answerId].translations[language]){
			const answerTranslationDirectusId = updatedNode.data.answers[answerId].translations[language].directus_id;
			//update existing answer translation item 
			answerTranslationDirectusId && updateAnswerTranslationsInDirectus(answerTranslationDirectusId, newData[language]); 
		} else {
			const answerDirectusId = updatedNode.data.answers[answerId].directus_id;
			answerDirectusId && createAnswerTranslationInDirectus(answerDirectusId, newData, language).then((request: any) => {
				//create answer translation item and set id in data
				updateTranslationId(request.id);
			}); 
		}
	};
};



export const useDeleteNodeAnswer = () => {
	const [, setEdges] = useAtom(edgesAtom);
	const [, setNodes] = useAtom(nodesAtom);

	return (nodeId: string, answerId: string, answerData: Answer) => {
		setNodes((nodes) => nodes.map(node => {
			if(node.id === nodeId && node.type === 'message') {
				deleteAnswerInDirectus(answerData);
				delete node.data.answers[answerId];
				node.data = { ...node.data};
			}
			return {...node};
		}));

		// remove edges connected to the sourceHandle of the answer 
		setEdges((edges) => edges.filter(edge => {
			if(edge.sourceHandle !== `message-node-${nodeId}-${answerId}`) return edge;
		}));

	};
};