const ContextIcon = () => {
	return (
		<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M28.5049 18.5358C30.761 17.642 31.5097 16.585 31.5097 15.6573C31.5097 14.2779 30.5953 12.215 28.6115 10.4419C26.6794 8.715 23.865 7.39703 20.3569 7.39703C16.8487 7.39703 14.0343 8.715 12.1022 10.4419C10.1184 12.215 9.20403 14.2779 9.20403 15.6573C9.20403 16.585 9.95269 17.642 12.2088 18.5358C14.3613 19.3884 17.3325 19.833 20.3569 19.833C23.3812 19.833 26.3524 19.3884 28.5049 18.5358ZM20.3569 21.433C26.6525 21.433 33.1097 19.5991 33.1097 15.6573C33.1097 11.7155 28.2088 5.79703 20.3569 5.79703C12.5049 5.79703 7.60403 11.7155 7.60403 15.6573C7.60403 19.5991 14.0612 21.433 20.3569 21.433Z" fill="black" />
			<path fillRule="evenodd" clipRule="evenodd" d="M10.2495 24.6541C10.2495 24.955 10.3309 25.3387 10.4888 25.7748C11.4226 28.353 15.0345 32.7658 20.3577 32.7658C25.6808 32.7658 29.2927 28.353 30.2265 25.7748C30.3845 25.3387 30.4658 24.955 30.4658 24.6541C30.4658 22.7047 29.1706 22.706 21.7119 22.7138C21.214 22.7143 20.6888 22.7149 20.1346 22.7149C19.5786 22.7149 19.0536 22.7143 18.5577 22.7138C11.1522 22.706 10.2495 22.7051 10.2495 24.6541ZM29.9646 24.0306C29.9646 24.0306 29.9646 24.0306 29.9646 24.0306V24.0306ZM28.8649 24.5887C28.7685 24.5596 28.6147 24.5221 28.3807 24.4864C27.2198 24.3095 25.2253 24.3111 21.6713 24.3141C21.1885 24.3145 20.6768 24.3149 20.1346 24.3149C19.5718 24.3149 19.0426 24.3143 18.5444 24.3138C17.0699 24.3123 15.868 24.311 14.8764 24.3253C13.5325 24.3447 12.682 24.3925 12.1308 24.4834C12.0118 24.503 11.9203 24.5227 11.8513 24.5401C11.8502 24.5733 11.8495 24.6112 11.8495 24.6541C11.8495 24.6668 11.8505 24.6831 11.8527 24.703C12.1766 24.8708 12.5725 25.0563 13.0412 25.2427C14.6268 25.8734 17.0538 26.518 20.3577 26.518C23.6615 26.518 26.0885 25.8734 27.6741 25.2427C28.1428 25.0563 28.5387 24.8708 28.8626 24.703C28.8648 24.6831 28.8658 24.6668 28.8658 24.6541C28.8658 24.6302 28.8655 24.6084 28.8649 24.5887ZM27.827 26.8966C26.0776 27.5359 23.5947 28.118 20.3577 28.118C17.1206 28.118 14.6377 27.5359 12.8883 26.8966C13.2219 27.3804 13.6298 27.882 14.1072 28.3645C15.6495 29.9235 17.7902 31.1658 20.3577 31.1658C22.9251 31.1658 25.0659 29.9235 26.6081 28.3645C27.0855 27.882 27.4934 27.3804 27.827 26.8966Z" fill="black" />
			<path fillRule="evenodd" clipRule="evenodd" d="M26.5741 16.5024C27.9263 16.0462 28.0679 15.4977 28.0679 15.3513C28.0679 13.7631 26.2058 10.3033 20.3577 10.3033C14.5095 10.3033 12.6474 13.7631 12.6474 15.3513C12.6474 15.5004 12.761 16.0156 14.0811 16.4474C15.3048 16.8476 17.0757 16.9582 18.9523 16.6201C19.9048 16.4484 20.8716 16.4746 21.7944 16.6657C23.5788 17.0352 25.336 16.92 26.5741 16.5024ZM21.47 18.2324C25.5143 19.0699 29.6679 17.7846 29.6679 15.3513C29.6679 12.6936 26.8915 8.70328 20.3577 8.70328C13.8238 8.70328 11.0474 12.6936 11.0474 15.3513C11.0474 17.7896 15.0813 18.9434 19.236 18.1947C19.9748 18.0616 20.7349 18.0802 21.47 18.2324Z" fill="black" />
			<path fillRule="evenodd" clipRule="evenodd" d="M7.56349 12.9339C6.2964 11.6456 6.34122 9.55776 7.66245 8.32454L9.99077 6.15129C11.3131 4.91707 13.3883 5.02919 14.5704 6.3987L15.1157 7.03044L13.9108 8.0827L13.3656 7.45096C12.7745 6.7662 11.7369 6.71015 11.0757 7.32725L8.74739 9.5005C8.08678 10.1171 8.06437 11.161 8.69791 11.8052L9.35754 12.4759L8.22311 13.6046L7.56349 12.9339Z" fill="black" />
			<path fillRule="evenodd" clipRule="evenodd" d="M33.1667 13.0443C34.4492 11.7587 34.4115 9.65806 33.0837 8.41873L30.77 6.25909C29.4469 5.02408 27.37 5.13724 26.1884 6.50872L25.6437 7.14092L26.8496 8.19207L27.3943 7.55987C27.9851 6.87413 29.0235 6.81755 29.6851 7.43505L31.9988 9.5947C32.6627 10.2144 32.6815 11.2647 32.0403 11.9075L31.3204 12.629L32.4469 13.7658L33.1667 13.0443Z" fill="black" />
			<path d="M18.1011 13.9403C18.1011 14.6579 17.5193 15.2397 16.8016 15.2397C16.0839 15.2397 15.5021 14.6579 15.5021 13.9403C15.5021 13.2226 16.0839 12.6408 16.8016 12.6408C17.5193 12.6408 18.1011 13.2226 18.1011 13.9403Z" fill="black" />
			<path d="M25.2132 13.9403C25.2132 14.6579 24.6314 15.2397 23.9137 15.2397C23.196 15.2397 22.6142 14.6579 22.6142 13.9403C22.6142 13.2226 23.196 12.6408 23.9137 12.6408C24.6314 12.6408 25.2132 13.2226 25.2132 13.9403Z" fill="black" />
			<path d="M26.4127 26.8158C26.4127 27.6994 25.6963 28.4158 24.8127 28.4158C23.929 28.4158 23.2127 27.6994 23.2127 26.8158C23.2127 25.9321 23.929 25.2158 24.8127 25.2158C25.6963 25.2158 26.4127 25.9321 26.4127 26.8158Z" fill="black" />
		</svg>


	);
};

export default ContextIcon;